:root {
    --tr-font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
}

html {
    font-family: var(--tr-font-family);
}

/*
1. Making button's default color transparent
2. Correct the inability to style clickable types in iOS and Safari.
*/

button {
    background-color: transparent;
    -moz-appearance: button;
         appearance: button;
    -webkit-appearance: button;
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
6. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

.tremor-base {
    box-sizing: border-box; /* 1 */
    line-height: 1.5; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -moz-tab-size: 4; /* 3 */
    -o-tab-size: 4;
       tab-size: 4; /* 3 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: #e5e7eb; /* 2 */
}

.tremor-base :is(
    *,
    ::before,
    ::after
) {
    box-sizing: inherit; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: #e5e7eb; /* 2 */
    line-height: inherit;
}

/*
Remove default margins from all text elements
*/

.text-elem {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

.input-elem {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: inherit; /* 1 */ 
    padding: 0;
}

/*
Removes default list styles
*/

.list-element {
    list-style: none;
    margin: 0;
    padding: 0;
}

/*
Set tooltip font-family
*/

.tremor-base ::before,
.tremor-base ::after,
::after.tremor-base,
::before.tremor-base {
  --tw-content: '';
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

.tremor-base abbr:where([title]),
abbr:where([title]).tremor-base {
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

.tremor-base table,
table.tremor-base {
    text-indent: 0; /* 1 */
    border-color: inherit; /* 2 */
    border-collapse: collapse; /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

.tremor-base button,
.tremor-base select,
button.tremor-base,
select.tremor-base {
  text-transform: none;
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

.tremor-base :-moz-focusring,
:-moz-focusring.tremor-base {
    outline: auto;
}
  
/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

.tremor-base :-moz-ui-invalid,
:-moz-ui-invalid.tremor-base {
    box-shadow: none;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

.tremor-base ::-webkit-inner-spin-button,
.tremor-base ::-webkit-outer-spin-button,
::-webkit-inner-spin-button.tremor-base,
::-webkit-outer-spin-button.tremor-base {
    height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

.tremor-base [type='search'],
[type='search'].tremor-base {
    -moz-appearance: textfield;
         appearance: textfield;
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

.tremor-base ::-webkit-search-decoration,
::-webkit-search-decoration.tremor-base {
    -webkit-appearance: none;
}  

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

.tremor-base ::-webkit-file-upload-button,
::-webkit-file-upload-button.tremor-base {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
*/

.tremor-base input::-moz-placeholder,
.tremor-base textarea::-moz-placeholder,
input::-moz-placeholder.tremor-base,
textarea::-moz-placeholder.tremor-base {
    opacity: 1; /* 1 */
}
  
.tremor-base input:-ms-input-placeholder,
.tremor-base textarea:-ms-input-placeholder,
input:-ms-input-placeholder.tremor-base,
textarea:-ms-input-placeholder.tremor-base {
    opacity: 1; /* 1 */
}

.tremor-base input::-moz-placeholder, .tremor-base textarea::-moz-placeholder, input::-moz-placeholder.tremor-base, textarea::-moz-placeholder.tremor-base {
    opacity: 1; /* 1 */
}

.tremor-base input:-ms-input-placeholder, .tremor-base textarea:-ms-input-placeholder, input:-ms-input-placeholder.tremor-base, textarea:-ms-input-placeholder.tremor-base {
    opacity: 1; /* 1 */
}

.tremor-base input::placeholder,
.tremor-base textarea::placeholder,
input::placeholder.tremor-base,
textarea::placeholder.tremor-base {
    opacity: 1; /* 1 */
}

/*
Set the default cursor for buttons.
*/

.tremor-base button,
.tremor-base [role="button"],
button.tremor-base,
[role="button"].tremor-base {
    cursor: pointer;
}

.tremor-base :disabled,
:disabled.tremor-base {
    cursor: not-allowed;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

.tremor-base :is(
    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object
) {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}

/*
Ensure the default browser behavior of the `hidden` attribute.
*/

.tremor-base [hidden],
[hidden].tremor-base {
    display: none;
}

*, ::before, ::after {
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
.tr-absolute {
    position: absolute;
}
.tr-relative {
    position: relative;
}
.tr-sticky {
    position: -webkit-sticky;
    position: sticky;
}
.tr-top-1\/2 {
    top: 50%;
}
.tr-left-1\/2 {
    left: 50%;
}
.tr-bottom-0 {
    bottom: 0px;
}
.tr-right-1\/2 {
    right: 50%;
}
.tr-left-0 {
    left: 0px;
}
.tr-right-0 {
    right: 0px;
}
.tr-top-0 {
    top: 0px;
}
.tr-left-px {
    left: 1px;
}
.tr-right-px {
    right: 1px;
}
.tr-top-px {
    top: 1px;
}
.tr-bottom-px {
    bottom: 1px;
}
.tr-left-0\.5 {
    left: 0.125rem;
}
.tr-right-0\.5 {
    right: 0.125rem;
}
.tr-top-0\.5 {
    top: 0.125rem;
}
.tr-bottom-0\.5 {
    bottom: 0.125rem;
}
.tr-left-1 {
    left: 0.25rem;
}
.tr-right-1 {
    right: 0.25rem;
}
.tr-top-1 {
    top: 0.25rem;
}
.tr-bottom-1 {
    bottom: 0.25rem;
}
.tr-left-1\.5 {
    left: 0.375rem;
}
.tr-right-1\.5 {
    right: 0.375rem;
}
.tr-top-1\.5 {
    top: 0.375rem;
}
.tr-bottom-1\.5 {
    bottom: 0.375rem;
}
.tr-left-2 {
    left: 0.5rem;
}
.tr-right-2 {
    right: 0.5rem;
}
.tr-bottom-2 {
    bottom: 0.5rem;
}
.tr-left-2\.5 {
    left: 0.625rem;
}
.tr-right-2\.5 {
    right: 0.625rem;
}
.tr-top-2\.5 {
    top: 0.625rem;
}
.tr-top-2 {
    top: 0.5rem;
}
.tr-bottom-2\.5 {
    bottom: 0.625rem;
}
.tr-left-3 {
    left: 0.75rem;
}
.tr-right-3 {
    right: 0.75rem;
}
.tr-top-3 {
    top: 0.75rem;
}
.tr-bottom-3 {
    bottom: 0.75rem;
}
.tr-left-3\.5 {
    left: 0.875rem;
}
.tr-right-3\.5 {
    right: 0.875rem;
}
.tr-top-3\.5 {
    top: 0.875rem;
}
.tr-bottom-3\.5 {
    bottom: 0.875rem;
}
.tr-left-4 {
    left: 1rem;
}
.tr-right-4 {
    right: 1rem;
}
.tr-top-4 {
    top: 1rem;
}
.tr-bottom-4 {
    bottom: 1rem;
}
.tr-left-6 {
    left: 1.5rem;
}
.tr-right-6 {
    right: 1.5rem;
}
.tr-top-6 {
    top: 1.5rem;
}
.tr-bottom-6 {
    bottom: 1.5rem;
}
.tr-left-8 {
    left: 2rem;
}
.tr-right-8 {
    right: 2rem;
}
.tr-top-8 {
    top: 2rem;
}
.tr-bottom-8 {
    bottom: 2rem;
}
.tr-z-10 {
    z-index: 10;
}
.tr-col-span-1 {
    grid-column: span 1 / span 1;
}
.tr-col-span-2 {
    grid-column: span 2 / span 2;
}
.tr-col-span-3 {
    grid-column: span 3 / span 3;
}
.tr-col-span-4 {
    grid-column: span 4 / span 4;
}
.tr-col-span-5 {
    grid-column: span 5 / span 5;
}
.tr-col-span-6 {
    grid-column: span 6 / span 6;
}
.tr-col-span-7 {
    grid-column: span 7 / span 7;
}
.tr-col-span-8 {
    grid-column: span 8 / span 8;
}
.tr-col-span-9 {
    grid-column: span 9 / span 9;
}
.tr-col-span-10 {
    grid-column: span 10 / span 10;
}
.tr-col-span-11 {
    grid-column: span 11 / span 11;
}
.tr-col-span-12 {
    grid-column: span 12 / span 12;
}
.tr-mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.tr-mt-0 {
    margin-top: 0px;
}
.tr-mt-0\.5 {
    margin-top: 0.125rem;
}
.tr-mt-1 {
    margin-top: 0.25rem;
}
.tr-mt-1\.5 {
    margin-top: 0.375rem;
}
.tr-mt-2 {
    margin-top: 0.5rem;
}
.tr-mt-2\.5 {
    margin-top: 0.625rem;
}
.tr-mt-3 {
    margin-top: 0.75rem;
}
.tr-mt-3\.5 {
    margin-top: 0.875rem;
}
.tr-mt-4 {
    margin-top: 1rem;
}
.tr-mt-5 {
    margin-top: 1.25rem;
}
.tr-mt-6 {
    margin-top: 1.5rem;
}
.tr-mt-7 {
    margin-top: 1.75rem;
}
.tr-mt-8 {
    margin-top: 2rem;
}
.tr-mt-9 {
    margin-top: 2.25rem;
}
.tr-mt-10 {
    margin-top: 2.5rem;
}
.tr-mt-11 {
    margin-top: 2.75rem;
}
.tr-mt-12 {
    margin-top: 3rem;
}
.tr-mt-14 {
    margin-top: 3.5rem;
}
.tr-mt-16 {
    margin-top: 4rem;
}
.tr-mt-20 {
    margin-top: 5rem;
}
.tr-mt-24 {
    margin-top: 6rem;
}
.tr-mt-28 {
    margin-top: 7rem;
}
.tr-mt-32 {
    margin-top: 8rem;
}
.tr-mt-36 {
    margin-top: 9rem;
}
.tr-mt-40 {
    margin-top: 10rem;
}
.tr-mt-44 {
    margin-top: 11rem;
}
.tr-mt-48 {
    margin-top: 12rem;
}
.tr-mt-52 {
    margin-top: 13rem;
}
.tr-mt-56 {
    margin-top: 14rem;
}
.tr-mt-60 {
    margin-top: 15rem;
}
.tr-mt-64 {
    margin-top: 16rem;
}
.tr-mt-72 {
    margin-top: 18rem;
}
.tr-mt-80 {
    margin-top: 20rem;
}
.tr-mt-96 {
    margin-top: 24rem;
}
.-tr-mt-0\.5 {
    margin-top: -0.125rem;
}
.-tr-mt-0 {
    margin-top: -0px;
}
.-tr-mt-1 {
    margin-top: -0.25rem;
}
.-tr-mt-1\.5 {
    margin-top: -0.375rem;
}
.-tr-mt-2 {
    margin-top: -0.5rem;
}
.-tr-mt-2\.5 {
    margin-top: -0.625rem;
}
.-tr-mt-3 {
    margin-top: -0.75rem;
}
.-tr-mt-3\.5 {
    margin-top: -0.875rem;
}
.-tr-mt-4 {
    margin-top: -1rem;
}
.-tr-mt-5 {
    margin-top: -1.25rem;
}
.-tr-mt-6 {
    margin-top: -1.5rem;
}
.-tr-mt-7 {
    margin-top: -1.75rem;
}
.-tr-mt-8 {
    margin-top: -2rem;
}
.-tr-mt-9 {
    margin-top: -2.25rem;
}
.-tr-mt-10 {
    margin-top: -2.5rem;
}
.-tr-mt-11 {
    margin-top: -2.75rem;
}
.-tr-mt-12 {
    margin-top: -3rem;
}
.-tr-mt-14 {
    margin-top: -3.5rem;
}
.-tr-mt-16 {
    margin-top: -4rem;
}
.-tr-mt-20 {
    margin-top: -5rem;
}
.-tr-mt-24 {
    margin-top: -6rem;
}
.-tr-mt-28 {
    margin-top: -7rem;
}
.-tr-mt-32 {
    margin-top: -8rem;
}
.-tr-mt-36 {
    margin-top: -9rem;
}
.-tr-mt-40 {
    margin-top: -10rem;
}
.-tr-mt-44 {
    margin-top: -11rem;
}
.-tr-mt-48 {
    margin-top: -12rem;
}
.-tr-mt-52 {
    margin-top: -13rem;
}
.-tr-mt-56 {
    margin-top: -14rem;
}
.-tr-mt-60 {
    margin-top: -15rem;
}
.-tr-mt-64 {
    margin-top: -16rem;
}
.-tr-mt-72 {
    margin-top: -18rem;
}
.-tr-mt-80 {
    margin-top: -20rem;
}
.-tr-mt-96 {
    margin-top: -24rem;
}
.tr-ml-0 {
    margin-left: 0px;
}
.tr-mr-0 {
    margin-right: 0px;
}
.tr-mb-0 {
    margin-bottom: 0px;
}
.-tr-ml-0 {
    margin-left: -0px;
}
.-tr-mr-0 {
    margin-right: -0px;
}
.-tr-mb-0 {
    margin-bottom: -0px;
}
.tr-ml-px {
    margin-left: 1px;
}
.tr-mt-px {
    margin-top: 1px;
}
.tr-mr-px {
    margin-right: 1px;
}
.tr-mb-px {
    margin-bottom: 1px;
}
.-tr-ml-px {
    margin-left: -1px;
}
.-tr-mr-px {
    margin-right: -1px;
}
.-tr-mt-px {
    margin-top: -1px;
}
.-tr-mb-px {
    margin-bottom: -1px;
}
.tr-ml-0\.5 {
    margin-left: 0.125rem;
}
.tr-mr-0\.5 {
    margin-right: 0.125rem;
}
.tr-mb-0\.5 {
    margin-bottom: 0.125rem;
}
.-tr-ml-0\.5 {
    margin-left: -0.125rem;
}
.-tr-mr-0\.5 {
    margin-right: -0.125rem;
}
.-tr-mb-0\.5 {
    margin-bottom: -0.125rem;
}
.tr-ml-1 {
    margin-left: 0.25rem;
}
.tr-mr-1 {
    margin-right: 0.25rem;
}
.tr-mb-1 {
    margin-bottom: 0.25rem;
}
.-tr-ml-1 {
    margin-left: -0.25rem;
}
.-tr-mr-1 {
    margin-right: -0.25rem;
}
.tr-ml-1\.5 {
    margin-left: 0.375rem;
}
.tr-mr-1\.5 {
    margin-right: 0.375rem;
}
.tr-mb-1\.5 {
    margin-bottom: 0.375rem;
}
.-tr-ml-1\.5 {
    margin-left: -0.375rem;
}
.-tr-mr-1\.5 {
    margin-right: -0.375rem;
}
.-tr-mb-1\.5 {
    margin-bottom: -0.375rem;
}
.-tr-mb-1 {
    margin-bottom: -0.25rem;
}
.tr-ml-2 {
    margin-left: 0.5rem;
}
.tr-mr-2 {
    margin-right: 0.5rem;
}
.tr-mb-2 {
    margin-bottom: 0.5rem;
}
.-tr-ml-2 {
    margin-left: -0.5rem;
}
.-tr-mr-2 {
    margin-right: -0.5rem;
}
.-tr-mb-2 {
    margin-bottom: -0.5rem;
}
.tr-ml-2\.5 {
    margin-left: 0.625rem;
}
.tr-mr-2\.5 {
    margin-right: 0.625rem;
}
.tr-mb-2\.5 {
    margin-bottom: 0.625rem;
}
.-tr-ml-2\.5 {
    margin-left: -0.625rem;
}
.-tr-mr-2\.5 {
    margin-right: -0.625rem;
}
.-tr-mb-2\.5 {
    margin-bottom: -0.625rem;
}
.tr-ml-3 {
    margin-left: 0.75rem;
}
.tr-mr-3 {
    margin-right: 0.75rem;
}
.tr-mb-3 {
    margin-bottom: 0.75rem;
}
.-tr-ml-3 {
    margin-left: -0.75rem;
}
.-tr-mr-3 {
    margin-right: -0.75rem;
}
.-tr-mb-3 {
    margin-bottom: -0.75rem;
}
.tr-ml-3\.5 {
    margin-left: 0.875rem;
}
.tr-mr-3\.5 {
    margin-right: 0.875rem;
}
.tr-mb-3\.5 {
    margin-bottom: 0.875rem;
}
.-tr-ml-3\.5 {
    margin-left: -0.875rem;
}
.-tr-mr-3\.5 {
    margin-right: -0.875rem;
}
.-tr-mb-3\.5 {
    margin-bottom: -0.875rem;
}
.tr-ml-4 {
    margin-left: 1rem;
}
.tr-mr-4 {
    margin-right: 1rem;
}
.tr-mb-4 {
    margin-bottom: 1rem;
}
.-tr-ml-4 {
    margin-left: -1rem;
}
.-tr-mr-4 {
    margin-right: -1rem;
}
.-tr-mb-4 {
    margin-bottom: -1rem;
}
.tr-ml-6 {
    margin-left: 1.5rem;
}
.tr-mr-6 {
    margin-right: 1.5rem;
}
.tr-mb-6 {
    margin-bottom: 1.5rem;
}
.-tr-ml-6 {
    margin-left: -1.5rem;
}
.-tr-mr-6 {
    margin-right: -1.5rem;
}
.-tr-mb-6 {
    margin-bottom: -1.5rem;
}
.tr-ml-8 {
    margin-left: 2rem;
}
.tr-mr-8 {
    margin-right: 2rem;
}
.tr-mb-8 {
    margin-bottom: 2rem;
}
.-tr-ml-8 {
    margin-left: -2rem;
}
.-tr-mr-8 {
    margin-right: -2rem;
}
.-tr-mb-8 {
    margin-bottom: -2rem;
}
.tr-block {
    display: block;
}
.tr-flex {
    display: flex;
}
.tr-inline-flex {
    display: inline-flex;
}
.tr-grid {
    display: grid;
}
.tr-hidden {
    display: none;
}
.tr-h-full {
    height: 100%;
}
.tr-h-0 {
    height: 0px;
}
.tr-h-0\.5 {
    height: 0.125rem;
}
.tr-h-1 {
    height: 0.25rem;
}
.tr-h-1\.5 {
    height: 0.375rem;
}
.tr-h-2 {
    height: 0.5rem;
}
.tr-h-2\.5 {
    height: 0.625rem;
}
.tr-h-3 {
    height: 0.75rem;
}
.tr-h-3\.5 {
    height: 0.875rem;
}
.tr-h-4 {
    height: 1rem;
}
.tr-h-5 {
    height: 1.25rem;
}
.tr-h-6 {
    height: 1.5rem;
}
.tr-h-7 {
    height: 1.75rem;
}
.tr-h-8 {
    height: 2rem;
}
.tr-h-9 {
    height: 2.25rem;
}
.tr-h-10 {
    height: 2.5rem;
}
.tr-h-11 {
    height: 2.75rem;
}
.tr-h-12 {
    height: 3rem;
}
.tr-h-14 {
    height: 3.5rem;
}
.tr-h-16 {
    height: 4rem;
}
.tr-h-20 {
    height: 5rem;
}
.tr-h-24 {
    height: 6rem;
}
.tr-h-28 {
    height: 7rem;
}
.tr-h-32 {
    height: 8rem;
}
.tr-h-36 {
    height: 9rem;
}
.tr-h-40 {
    height: 10rem;
}
.tr-h-44 {
    height: 11rem;
}
.tr-h-48 {
    height: 12rem;
}
.tr-h-52 {
    height: 13rem;
}
.tr-h-56 {
    height: 14rem;
}
.tr-h-60 {
    height: 15rem;
}
.tr-h-64 {
    height: 16rem;
}
.tr-h-72 {
    height: 18rem;
}
.tr-h-80 {
    height: 20rem;
}
.tr-h-96 {
    height: 24rem;
}
.tr-max-h-fit {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
}
.tr-max-h-72 {
    max-height: 18rem;
}
.tr-w-full {
    width: 100%;
}
.tr-w-48 {
    width: 12rem;
}
.tr-w-1\/2 {
    width: 50%;
}
.tr-w-16 {
    width: 4rem;
}
.tr-w-0 {
    width: 0px;
}
.tr-w-0\.5 {
    width: 0.125rem;
}
.tr-w-1 {
    width: 0.25rem;
}
.tr-w-1\.5 {
    width: 0.375rem;
}
.tr-w-2 {
    width: 0.5rem;
}
.tr-w-2\.5 {
    width: 0.625rem;
}
.tr-w-3 {
    width: 0.75rem;
}
.tr-w-3\.5 {
    width: 0.875rem;
}
.tr-w-4 {
    width: 1rem;
}
.tr-w-5 {
    width: 1.25rem;
}
.tr-w-6 {
    width: 1.5rem;
}
.tr-w-7 {
    width: 1.75rem;
}
.tr-w-8 {
    width: 2rem;
}
.tr-w-9 {
    width: 2.25rem;
}
.tr-w-10 {
    width: 2.5rem;
}
.tr-w-11 {
    width: 2.75rem;
}
.tr-w-12 {
    width: 3rem;
}
.tr-w-14 {
    width: 3.5rem;
}
.tr-w-20 {
    width: 5rem;
}
.tr-w-24 {
    width: 6rem;
}
.tr-w-28 {
    width: 7rem;
}
.tr-w-32 {
    width: 8rem;
}
.tr-w-36 {
    width: 9rem;
}
.tr-w-40 {
    width: 10rem;
}
.tr-w-44 {
    width: 11rem;
}
.tr-w-52 {
    width: 13rem;
}
.tr-w-56 {
    width: 14rem;
}
.tr-w-60 {
    width: 15rem;
}
.tr-w-64 {
    width: 16rem;
}
.tr-w-72 {
    width: 18rem;
}
.tr-w-80 {
    width: 20rem;
}
.tr-w-96 {
    width: 24rem;
}
.tr-min-w-\[10rem\] {
    min-width: 10rem;
}
.tr-min-w-min {
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
}
.tr-max-w-xs {
    max-width: 20rem;
}
.tr-max-w-full {
    max-width: 100%;
}
.tr-max-w-0 {
    max-width: 0rem;
}
.tr-max-w-none {
    max-width: none;
}
.tr-max-w-sm {
    max-width: 24rem;
}
.tr-max-w-md {
    max-width: 28rem;
}
.tr-max-w-lg {
    max-width: 32rem;
}
.tr-max-w-xl {
    max-width: 36rem;
}
.tr-max-w-2xl {
    max-width: 42rem;
}
.tr-max-w-3xl {
    max-width: 48rem;
}
.tr-max-w-4xl {
    max-width: 56rem;
}
.tr-max-w-5xl {
    max-width: 64rem;
}
.tr-max-w-6xl {
    max-width: 72rem;
}
.tr-max-w-7xl {
    max-width: 80rem;
}
.tr-max-w-min {
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
}
.tr-max-w-max {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
}
.tr-max-w-fit {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.tr-max-w-prose {
    max-width: 65ch;
}
.tr-max-w-screen-sm {
    max-width: 640px;
}
.tr-max-w-screen-lg {
    max-width: 1024px;
}
.tr-max-w-screen-xl {
    max-width: 1280px;
}
.tr-max-w-screen-2xl {
    max-width: 1536px;
}
.tr-flex-none {
    flex: none;
}
.tr-flex-1 {
    flex: 1 1 0%;
}
.tr-flex-shrink-0 {
    flex-shrink: 0;
}
.tr-shrink-0 {
    flex-shrink: 0;
}
.-tr-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tr-translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-tr-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@-webkit-keyframes tr-spin {

    to {
        transform: rotate(360deg);
    }
}
@keyframes tr-spin {

    to {
        transform: rotate(360deg);
    }
}
.tr-animate-spin {
    -webkit-animation: tr-spin 1s linear infinite;
            animation: tr-spin 1s linear infinite;
}
.tr-cursor-pointer {
    cursor: pointer;
}
.tr-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
}
.tr-grid-cols-none {
    grid-template-columns: none;
}
.tr-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.tr-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.tr-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.tr-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.tr-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}
.tr-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}
.tr-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
}
.tr-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
}
.tr-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
}
.tr-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
}
.tr-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}
.tr-flex-col {
    flex-direction: column;
}
.tr-flex-wrap {
    flex-wrap: wrap;
}
.tr-flex-nowrap {
    flex-wrap: nowrap;
}
.tr-items-start {
    align-items: flex-start;
}
.tr-items-end {
    align-items: flex-end;
}
.tr-items-center {
    align-items: center;
}
.tr-items-baseline {
    align-items: baseline;
}
.tr-items-stretch {
    align-items: stretch;
}
.tr-justify-start {
    justify-content: flex-start;
}
.tr-justify-end {
    justify-content: flex-end;
}
.tr-justify-center {
    justify-content: center;
}
.tr-justify-between {
    justify-content: space-between;
}
.tr-justify-around {
    justify-content: space-around;
}
.tr-justify-evenly {
    justify-content: space-evenly;
}
.tr-gap-x-0 {
    -moz-column-gap: 0px;
         column-gap: 0px;
}
.tr-gap-x-0\.5 {
    -moz-column-gap: 0.125rem;
         column-gap: 0.125rem;
}
.tr-gap-x-1 {
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
}
.tr-gap-x-1\.5 {
    -moz-column-gap: 0.375rem;
         column-gap: 0.375rem;
}
.tr-gap-x-2 {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
}
.tr-gap-x-2\.5 {
    -moz-column-gap: 0.625rem;
         column-gap: 0.625rem;
}
.tr-gap-x-3 {
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
}
.tr-gap-x-3\.5 {
    -moz-column-gap: 0.875rem;
         column-gap: 0.875rem;
}
.tr-gap-x-4 {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
}
.tr-gap-x-5 {
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
}
.tr-gap-x-6 {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
}
.tr-gap-x-7 {
    -moz-column-gap: 1.75rem;
         column-gap: 1.75rem;
}
.tr-gap-x-8 {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
}
.tr-gap-x-9 {
    -moz-column-gap: 2.25rem;
         column-gap: 2.25rem;
}
.tr-gap-x-10 {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
}
.tr-gap-x-11 {
    -moz-column-gap: 2.75rem;
         column-gap: 2.75rem;
}
.tr-gap-x-12 {
    -moz-column-gap: 3rem;
         column-gap: 3rem;
}
.tr-gap-x-14 {
    -moz-column-gap: 3.5rem;
         column-gap: 3.5rem;
}
.tr-gap-x-16 {
    -moz-column-gap: 4rem;
         column-gap: 4rem;
}
.tr-gap-x-20 {
    -moz-column-gap: 5rem;
         column-gap: 5rem;
}
.tr-gap-x-24 {
    -moz-column-gap: 6rem;
         column-gap: 6rem;
}
.tr-gap-x-28 {
    -moz-column-gap: 7rem;
         column-gap: 7rem;
}
.tr-gap-x-32 {
    -moz-column-gap: 8rem;
         column-gap: 8rem;
}
.tr-gap-x-36 {
    -moz-column-gap: 9rem;
         column-gap: 9rem;
}
.tr-gap-x-40 {
    -moz-column-gap: 10rem;
         column-gap: 10rem;
}
.tr-gap-x-44 {
    -moz-column-gap: 11rem;
         column-gap: 11rem;
}
.tr-gap-x-48 {
    -moz-column-gap: 12rem;
         column-gap: 12rem;
}
.tr-gap-x-52 {
    -moz-column-gap: 13rem;
         column-gap: 13rem;
}
.tr-gap-x-56 {
    -moz-column-gap: 14rem;
         column-gap: 14rem;
}
.tr-gap-x-60 {
    -moz-column-gap: 15rem;
         column-gap: 15rem;
}
.tr-gap-x-64 {
    -moz-column-gap: 16rem;
         column-gap: 16rem;
}
.tr-gap-x-72 {
    -moz-column-gap: 18rem;
         column-gap: 18rem;
}
.tr-gap-x-80 {
    -moz-column-gap: 20rem;
         column-gap: 20rem;
}
.tr-gap-x-96 {
    -moz-column-gap: 24rem;
         column-gap: 24rem;
}
.tr-gap-y-0 {
    row-gap: 0px;
}
.tr-gap-y-0\.5 {
    row-gap: 0.125rem;
}
.tr-gap-y-1 {
    row-gap: 0.25rem;
}
.tr-gap-y-1\.5 {
    row-gap: 0.375rem;
}
.tr-gap-y-2 {
    row-gap: 0.5rem;
}
.tr-gap-y-2\.5 {
    row-gap: 0.625rem;
}
.tr-gap-y-3 {
    row-gap: 0.75rem;
}
.tr-gap-y-3\.5 {
    row-gap: 0.875rem;
}
.tr-gap-y-4 {
    row-gap: 1rem;
}
.tr-gap-y-5 {
    row-gap: 1.25rem;
}
.tr-gap-y-6 {
    row-gap: 1.5rem;
}
.tr-gap-y-7 {
    row-gap: 1.75rem;
}
.tr-gap-y-8 {
    row-gap: 2rem;
}
.tr-gap-y-9 {
    row-gap: 2.25rem;
}
.tr-gap-y-10 {
    row-gap: 2.5rem;
}
.tr-gap-y-11 {
    row-gap: 2.75rem;
}
.tr-gap-y-12 {
    row-gap: 3rem;
}
.tr-gap-y-14 {
    row-gap: 3.5rem;
}
.tr-gap-y-16 {
    row-gap: 4rem;
}
.tr-gap-y-20 {
    row-gap: 5rem;
}
.tr-gap-y-24 {
    row-gap: 6rem;
}
.tr-gap-y-28 {
    row-gap: 7rem;
}
.tr-gap-y-32 {
    row-gap: 8rem;
}
.tr-gap-y-36 {
    row-gap: 9rem;
}
.tr-gap-y-40 {
    row-gap: 10rem;
}
.tr-gap-y-44 {
    row-gap: 11rem;
}
.tr-gap-y-48 {
    row-gap: 12rem;
}
.tr-gap-y-52 {
    row-gap: 13rem;
}
.tr-gap-y-56 {
    row-gap: 14rem;
}
.tr-gap-y-60 {
    row-gap: 15rem;
}
.tr-gap-y-64 {
    row-gap: 16rem;
}
.tr-gap-y-72 {
    row-gap: 18rem;
}
.tr-gap-y-80 {
    row-gap: 20rem;
}
.tr-gap-y-96 {
    row-gap: 24rem;
}
.tr-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.tr-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0px * var(--tw-space-x-reverse));
    margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
}
.-tr-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
}
.tr-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
.tr-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}
.tr-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.tr-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.tr-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
}
.tr-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.tr-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
}
.tr-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.tr-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.tr-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.tr-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}
.tr-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.tr-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}
.tr-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}
.tr-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
}
.tr-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}
.tr-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}
.tr-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}
.tr-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}
.tr-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}
.tr-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
}
.tr-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}
.tr-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
}
.tr-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}
.tr-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
}
.tr-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}
.tr-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
}
.tr-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}
.tr-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
}
.tr-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}
.tr-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
}
.tr-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
}
.tr-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
}
.-tr-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
}
.-tr-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0px * var(--tw-space-y-reverse));
}
.-tr-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
}
.-tr-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
}
.-tr-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
}
.-tr-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
}
.-tr-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
}
.-tr-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
}
.-tr-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}
.-tr-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}
.-tr-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}
.-tr-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
}
.-tr-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}
.-tr-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
}
.-tr-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}
.-tr-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
}
.-tr-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}
.-tr-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
}
.-tr-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}
.-tr-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}
.-tr-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}
.-tr-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
}
.-tr-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}
.-tr-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
}
.-tr-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}
.-tr-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
}
.-tr-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}
.-tr-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
}
.-tr-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}
.-tr-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
}
.-tr-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}
.-tr-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
}
.-tr-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
}
.-tr-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
}
.tr-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}
.tr-divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.tr-divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
}
.tr-divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-divide-opacity));
}
.tr-divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-divide-opacity));
}
.tr-divide-slate-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-divide-opacity));
}
.tr-divide-slate-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-divide-opacity));
}
.tr-divide-slate-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-divide-opacity));
}
.tr-divide-slate-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-divide-opacity));
}
.tr-divide-slate-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-divide-opacity));
}
.tr-divide-slate-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-divide-opacity));
}
.tr-divide-slate-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-divide-opacity));
}
.tr-divide-slate-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-divide-opacity));
}
.tr-divide-slate-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-divide-opacity));
}
.tr-divide-slate-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-divide-opacity));
}
.tr-divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-divide-opacity));
}
.tr-divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}
.tr-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}
.tr-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}
.tr-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-divide-opacity));
}
.tr-divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-divide-opacity));
}
.tr-divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-divide-opacity));
}
.tr-divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}
.tr-divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-divide-opacity));
}
.tr-divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-divide-opacity));
}
.tr-divide-zinc-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-divide-opacity));
}
.tr-divide-zinc-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-divide-opacity));
}
.tr-divide-zinc-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-divide-opacity));
}
.tr-divide-zinc-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-divide-opacity));
}
.tr-divide-zinc-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-divide-opacity));
}
.tr-divide-zinc-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-divide-opacity));
}
.tr-divide-zinc-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-divide-opacity));
}
.tr-divide-zinc-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-divide-opacity));
}
.tr-divide-zinc-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-divide-opacity));
}
.tr-divide-zinc-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-divide-opacity));
}
.tr-divide-neutral-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-divide-opacity));
}
.tr-divide-neutral-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-divide-opacity));
}
.tr-divide-neutral-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-divide-opacity));
}
.tr-divide-neutral-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-divide-opacity));
}
.tr-divide-neutral-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-divide-opacity));
}
.tr-divide-neutral-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-divide-opacity));
}
.tr-divide-neutral-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-divide-opacity));
}
.tr-divide-neutral-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-divide-opacity));
}
.tr-divide-neutral-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-divide-opacity));
}
.tr-divide-neutral-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-divide-opacity));
}
.tr-divide-stone-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-divide-opacity));
}
.tr-divide-stone-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-divide-opacity));
}
.tr-divide-stone-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-divide-opacity));
}
.tr-divide-stone-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-divide-opacity));
}
.tr-divide-stone-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-divide-opacity));
}
.tr-divide-stone-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-divide-opacity));
}
.tr-divide-stone-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-divide-opacity));
}
.tr-divide-stone-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-divide-opacity));
}
.tr-divide-stone-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-divide-opacity));
}
.tr-divide-stone-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-divide-opacity));
}
.tr-divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-divide-opacity));
}
.tr-divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-divide-opacity));
}
.tr-divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-divide-opacity));
}
.tr-divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-divide-opacity));
}
.tr-divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-divide-opacity));
}
.tr-divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-divide-opacity));
}
.tr-divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-divide-opacity));
}
.tr-divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-divide-opacity));
}
.tr-divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-divide-opacity));
}
.tr-divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-divide-opacity));
}
.tr-divide-orange-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-divide-opacity));
}
.tr-divide-orange-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-divide-opacity));
}
.tr-divide-orange-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-divide-opacity));
}
.tr-divide-orange-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-divide-opacity));
}
.tr-divide-orange-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-divide-opacity));
}
.tr-divide-orange-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-divide-opacity));
}
.tr-divide-orange-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-divide-opacity));
}
.tr-divide-orange-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-divide-opacity));
}
.tr-divide-orange-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-divide-opacity));
}
.tr-divide-orange-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-divide-opacity));
}
.tr-divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-divide-opacity));
}
.tr-divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-divide-opacity));
}
.tr-divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-divide-opacity));
}
.tr-divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-divide-opacity));
}
.tr-divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-divide-opacity));
}
.tr-divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-divide-opacity));
}
.tr-divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-divide-opacity));
}
.tr-divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-divide-opacity));
}
.tr-divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-divide-opacity));
}
.tr-divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-divide-opacity));
}
.tr-divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-divide-opacity));
}
.tr-divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-divide-opacity));
}
.tr-divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-divide-opacity));
}
.tr-divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-divide-opacity));
}
.tr-divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-divide-opacity));
}
.tr-divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-divide-opacity));
}
.tr-divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-divide-opacity));
}
.tr-divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-divide-opacity));
}
.tr-divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-divide-opacity));
}
.tr-divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-divide-opacity));
}
.tr-divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-divide-opacity));
}
.tr-divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-divide-opacity));
}
.tr-divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-divide-opacity));
}
.tr-divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-divide-opacity));
}
.tr-divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-divide-opacity));
}
.tr-divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-divide-opacity));
}
.tr-divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-divide-opacity));
}
.tr-divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-divide-opacity));
}
.tr-divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-divide-opacity));
}
.tr-divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-divide-opacity));
}
.tr-divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-divide-opacity));
}
.tr-divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-divide-opacity));
}
.tr-divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-divide-opacity));
}
.tr-divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-divide-opacity));
}
.tr-divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-divide-opacity));
}
.tr-divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-divide-opacity));
}
.tr-divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-divide-opacity));
}
.tr-divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-divide-opacity));
}
.tr-divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-divide-opacity));
}
.tr-divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-divide-opacity));
}
.tr-divide-emerald-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-divide-opacity));
}
.tr-divide-emerald-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-divide-opacity));
}
.tr-divide-emerald-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-divide-opacity));
}
.tr-divide-emerald-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-divide-opacity));
}
.tr-divide-emerald-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-divide-opacity));
}
.tr-divide-emerald-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-divide-opacity));
}
.tr-divide-emerald-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-divide-opacity));
}
.tr-divide-emerald-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-divide-opacity));
}
.tr-divide-emerald-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-divide-opacity));
}
.tr-divide-emerald-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-divide-opacity));
}
.tr-divide-teal-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-divide-opacity));
}
.tr-divide-teal-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-divide-opacity));
}
.tr-divide-teal-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-divide-opacity));
}
.tr-divide-teal-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-divide-opacity));
}
.tr-divide-teal-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-divide-opacity));
}
.tr-divide-teal-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-divide-opacity));
}
.tr-divide-teal-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-divide-opacity));
}
.tr-divide-teal-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-divide-opacity));
}
.tr-divide-teal-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-divide-opacity));
}
.tr-divide-teal-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-divide-opacity));
}
.tr-divide-cyan-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-divide-opacity));
}
.tr-divide-cyan-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-divide-opacity));
}
.tr-divide-cyan-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-divide-opacity));
}
.tr-divide-cyan-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-divide-opacity));
}
.tr-divide-cyan-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-divide-opacity));
}
.tr-divide-cyan-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-divide-opacity));
}
.tr-divide-cyan-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-divide-opacity));
}
.tr-divide-cyan-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-divide-opacity));
}
.tr-divide-cyan-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-divide-opacity));
}
.tr-divide-cyan-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-divide-opacity));
}
.tr-divide-sky-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-divide-opacity));
}
.tr-divide-sky-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-divide-opacity));
}
.tr-divide-sky-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-divide-opacity));
}
.tr-divide-sky-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-divide-opacity));
}
.tr-divide-sky-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-divide-opacity));
}
.tr-divide-sky-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-divide-opacity));
}
.tr-divide-sky-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-divide-opacity));
}
.tr-divide-sky-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-divide-opacity));
}
.tr-divide-sky-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-divide-opacity));
}
.tr-divide-sky-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-divide-opacity));
}
.tr-divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-divide-opacity));
}
.tr-divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-divide-opacity));
}
.tr-divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-divide-opacity));
}
.tr-divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-divide-opacity));
}
.tr-divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-divide-opacity));
}
.tr-divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-divide-opacity));
}
.tr-divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-divide-opacity));
}
.tr-divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-divide-opacity));
}
.tr-divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-divide-opacity));
}
.tr-divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-divide-opacity));
}
.tr-divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-divide-opacity));
}
.tr-divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-divide-opacity));
}
.tr-divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-divide-opacity));
}
.tr-divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-divide-opacity));
}
.tr-divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-divide-opacity));
}
.tr-divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-divide-opacity));
}
.tr-divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-divide-opacity));
}
.tr-divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-divide-opacity));
}
.tr-divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-divide-opacity));
}
.tr-divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-divide-opacity));
}
.tr-divide-violet-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-divide-opacity));
}
.tr-divide-violet-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-divide-opacity));
}
.tr-divide-violet-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-divide-opacity));
}
.tr-divide-violet-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-divide-opacity));
}
.tr-divide-violet-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-divide-opacity));
}
.tr-divide-violet-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-divide-opacity));
}
.tr-divide-violet-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-divide-opacity));
}
.tr-divide-violet-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-divide-opacity));
}
.tr-divide-violet-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-divide-opacity));
}
.tr-divide-violet-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-divide-opacity));
}
.tr-divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-divide-opacity));
}
.tr-divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-divide-opacity));
}
.tr-divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-divide-opacity));
}
.tr-divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-divide-opacity));
}
.tr-divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-divide-opacity));
}
.tr-divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-divide-opacity));
}
.tr-divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-divide-opacity));
}
.tr-divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-divide-opacity));
}
.tr-divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-divide-opacity));
}
.tr-divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-divide-opacity));
}
.tr-divide-fuchsia-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-divide-opacity));
}
.tr-divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-divide-opacity));
}
.tr-divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-divide-opacity));
}
.tr-divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-divide-opacity));
}
.tr-divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-divide-opacity));
}
.tr-divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-divide-opacity));
}
.tr-divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-divide-opacity));
}
.tr-divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-divide-opacity));
}
.tr-divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-divide-opacity));
}
.tr-divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-divide-opacity));
}
.tr-divide-rose-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-divide-opacity));
}
.tr-divide-rose-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-divide-opacity));
}
.tr-divide-rose-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-divide-opacity));
}
.tr-divide-rose-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-divide-opacity));
}
.tr-divide-rose-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-divide-opacity));
}
.tr-divide-rose-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-divide-opacity));
}
.tr-divide-rose-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-divide-opacity));
}
.tr-divide-rose-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-divide-opacity));
}
.tr-divide-rose-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-divide-opacity));
}
.tr-divide-rose-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-divide-opacity));
}
.tr-overflow-auto {
    overflow: auto;
}
.tr-overflow-hidden {
    overflow: hidden;
}
.tr-overflow-x-auto {
    overflow-x: auto;
}
.tr-overflow-y-auto {
    overflow-y: auto;
}
.tr-overflow-x-clip {
    overflow-x: clip;
}
.tr-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tr-whitespace-nowrap {
    white-space: nowrap;
}
.tr-rounded-none {
    border-radius: 0px;
}
.tr-rounded {
    border-radius: 0.25rem;
}
.tr-rounded-md {
    border-radius: 0.375rem;
}
.tr-rounded-lg {
    border-radius: 0.5rem;
}
.tr-rounded-full {
    border-radius: 9999px;
}
.tr-rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.tr-rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.tr-rounded-r-none {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.tr-rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.tr-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.tr-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.tr-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.tr-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.tr-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}
.tr-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}
.tr-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}
.tr-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}
.tr-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.tr-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
.tr-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.tr-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.tr-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
}
.tr-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
}
.tr-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}
.tr-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
}
.tr-border-0 {
    border-width: 0px;
}
.tr-border {
    border-width: 1px;
}
.tr-border-2 {
    border-width: 2px;
}
.tr-border-4 {
    border-width: 4px;
}
.tr-border-l-0 {
    border-left-width: 0px;
}
.tr-border-t-0 {
    border-top-width: 0px;
}
.tr-border-r-0 {
    border-right-width: 0px;
}
.tr-border-b-0 {
    border-bottom-width: 0px;
}
.tr-border-l {
    border-left-width: 1px;
}
.tr-border-t {
    border-top-width: 1px;
}
.tr-border-r {
    border-right-width: 1px;
}
.tr-border-b {
    border-bottom-width: 1px;
}
.tr-border-l-2 {
    border-left-width: 2px;
}
.tr-border-t-2 {
    border-top-width: 2px;
}
.tr-border-r-2 {
    border-right-width: 2px;
}
.tr-border-b-2 {
    border-bottom-width: 2px;
}
.tr-border-l-4 {
    border-left-width: 4px;
}
.tr-border-t-4 {
    border-top-width: 4px;
}
.tr-border-r-4 {
    border-right-width: 4px;
}
.tr-border-b-4 {
    border-bottom-width: 4px;
}
.tr-border-transparent {
    border-color: transparent;
}
.tr-border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.tr-border-black {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
.tr-border-slate-50 {
    --tw-border-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-border-opacity));
}
.tr-border-slate-100 {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity));
}
.tr-border-slate-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.tr-border-slate-300 {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.tr-border-slate-400 {
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity));
}
.tr-border-slate-500 {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity));
}
.tr-border-slate-600 {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity));
}
.tr-border-slate-700 {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity));
}
.tr-border-slate-800 {
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity));
}
.tr-border-slate-900 {
    --tw-border-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-border-opacity));
}
.tr-border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-border-opacity));
}
.tr-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.tr-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.tr-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.tr-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.tr-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}
.tr-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.tr-border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
.tr-border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity));
}
.tr-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}
.tr-border-zinc-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity));
}
.tr-border-zinc-100 {
    --tw-border-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-border-opacity));
}
.tr-border-zinc-200 {
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity));
}
.tr-border-zinc-300 {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity));
}
.tr-border-zinc-400 {
    --tw-border-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-border-opacity));
}
.tr-border-zinc-500 {
    --tw-border-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-border-opacity));
}
.tr-border-zinc-600 {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-border-opacity));
}
.tr-border-zinc-700 {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity));
}
.tr-border-zinc-800 {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity));
}
.tr-border-zinc-900 {
    --tw-border-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-border-opacity));
}
.tr-border-neutral-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity));
}
.tr-border-neutral-100 {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity));
}
.tr-border-neutral-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity));
}
.tr-border-neutral-300 {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity));
}
.tr-border-neutral-400 {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity));
}
.tr-border-neutral-500 {
    --tw-border-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-border-opacity));
}
.tr-border-neutral-600 {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-border-opacity));
}
.tr-border-neutral-700 {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity));
}
.tr-border-neutral-800 {
    --tw-border-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-border-opacity));
}
.tr-border-neutral-900 {
    --tw-border-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-border-opacity));
}
.tr-border-stone-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-border-opacity));
}
.tr-border-stone-100 {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-border-opacity));
}
.tr-border-stone-200 {
    --tw-border-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-border-opacity));
}
.tr-border-stone-300 {
    --tw-border-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-border-opacity));
}
.tr-border-stone-400 {
    --tw-border-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-border-opacity));
}
.tr-border-stone-500 {
    --tw-border-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-border-opacity));
}
.tr-border-stone-600 {
    --tw-border-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-border-opacity));
}
.tr-border-stone-700 {
    --tw-border-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-border-opacity));
}
.tr-border-stone-800 {
    --tw-border-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-border-opacity));
}
.tr-border-stone-900 {
    --tw-border-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-border-opacity));
}
.tr-border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-border-opacity));
}
.tr-border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-border-opacity));
}
.tr-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity));
}
.tr-border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity));
}
.tr-border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity));
}
.tr-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.tr-border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-border-opacity));
}
.tr-border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-border-opacity));
}
.tr-border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity));
}
.tr-border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-border-opacity));
}
.tr-border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-border-opacity));
}
.tr-border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-border-opacity));
}
.tr-border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-border-opacity));
}
.tr-border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-border-opacity));
}
.tr-border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity));
}
.tr-border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-border-opacity));
}
.tr-border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-border-opacity));
}
.tr-border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-border-opacity));
}
.tr-border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-border-opacity));
}
.tr-border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-border-opacity));
}
.tr-border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-border-opacity));
}
.tr-border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-border-opacity));
}
.tr-border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-border-opacity));
}
.tr-border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-border-opacity));
}
.tr-border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-border-opacity));
}
.tr-border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-border-opacity));
}
.tr-border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-border-opacity));
}
.tr-border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-border-opacity));
}
.tr-border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-border-opacity));
}
.tr-border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-border-opacity));
}
.tr-border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-border-opacity));
}
.tr-border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-border-opacity));
}
.tr-border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-border-opacity));
}
.tr-border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-border-opacity));
}
.tr-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity));
}
.tr-border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-border-opacity));
}
.tr-border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-border-opacity));
}
.tr-border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-border-opacity));
}
.tr-border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-border-opacity));
}
.tr-border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-border-opacity));
}
.tr-border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-border-opacity));
}
.tr-border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-border-opacity));
}
.tr-border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-border-opacity));
}
.tr-border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-border-opacity));
}
.tr-border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-border-opacity));
}
.tr-border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-border-opacity));
}
.tr-border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-border-opacity));
}
.tr-border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-border-opacity));
}
.tr-border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-border-opacity));
}
.tr-border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-border-opacity));
}
.tr-border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-border-opacity));
}
.tr-border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-border-opacity));
}
.tr-border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity));
}
.tr-border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-border-opacity));
}
.tr-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity));
}
.tr-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity));
}
.tr-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity));
}
.tr-border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-border-opacity));
}
.tr-border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-border-opacity));
}
.tr-border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-border-opacity));
}
.tr-border-emerald-50 {
    --tw-border-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-border-opacity));
}
.tr-border-emerald-100 {
    --tw-border-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-border-opacity));
}
.tr-border-emerald-200 {
    --tw-border-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-border-opacity));
}
.tr-border-emerald-300 {
    --tw-border-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-border-opacity));
}
.tr-border-emerald-400 {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity));
}
.tr-border-emerald-500 {
    --tw-border-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-border-opacity));
}
.tr-border-emerald-600 {
    --tw-border-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-border-opacity));
}
.tr-border-emerald-700 {
    --tw-border-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-border-opacity));
}
.tr-border-emerald-800 {
    --tw-border-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-border-opacity));
}
.tr-border-emerald-900 {
    --tw-border-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-border-opacity));
}
.tr-border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-border-opacity));
}
.tr-border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-border-opacity));
}
.tr-border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-border-opacity));
}
.tr-border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-border-opacity));
}
.tr-border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-border-opacity));
}
.tr-border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-border-opacity));
}
.tr-border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-border-opacity));
}
.tr-border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-border-opacity));
}
.tr-border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-border-opacity));
}
.tr-border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-border-opacity));
}
.tr-border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-border-opacity));
}
.tr-border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-border-opacity));
}
.tr-border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-border-opacity));
}
.tr-border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-border-opacity));
}
.tr-border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-border-opacity));
}
.tr-border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-border-opacity));
}
.tr-border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-border-opacity));
}
.tr-border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-border-opacity));
}
.tr-border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-border-opacity));
}
.tr-border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-border-opacity));
}
.tr-border-sky-50 {
    --tw-border-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-border-opacity));
}
.tr-border-sky-100 {
    --tw-border-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-border-opacity));
}
.tr-border-sky-200 {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-border-opacity));
}
.tr-border-sky-300 {
    --tw-border-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-border-opacity));
}
.tr-border-sky-400 {
    --tw-border-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-border-opacity));
}
.tr-border-sky-500 {
    --tw-border-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-border-opacity));
}
.tr-border-sky-600 {
    --tw-border-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-border-opacity));
}
.tr-border-sky-700 {
    --tw-border-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-border-opacity));
}
.tr-border-sky-800 {
    --tw-border-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-border-opacity));
}
.tr-border-sky-900 {
    --tw-border-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-border-opacity));
}
.tr-border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-border-opacity));
}
.tr-border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-border-opacity));
}
.tr-border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity));
}
.tr-border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity));
}
.tr-border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity));
}
.tr-border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
}
.tr-border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity));
}
.tr-border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity));
}
.tr-border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-border-opacity));
}
.tr-border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-border-opacity));
}
.tr-border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-border-opacity));
}
.tr-border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-border-opacity));
}
.tr-border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity));
}
.tr-border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity));
}
.tr-border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-border-opacity));
}
.tr-border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity));
}
.tr-border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
.tr-border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-border-opacity));
}
.tr-border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-border-opacity));
}
.tr-border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-border-opacity));
}
.tr-border-violet-50 {
    --tw-border-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-border-opacity));
}
.tr-border-violet-100 {
    --tw-border-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-border-opacity));
}
.tr-border-violet-200 {
    --tw-border-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-border-opacity));
}
.tr-border-violet-300 {
    --tw-border-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-border-opacity));
}
.tr-border-violet-400 {
    --tw-border-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-border-opacity));
}
.tr-border-violet-500 {
    --tw-border-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-border-opacity));
}
.tr-border-violet-600 {
    --tw-border-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-border-opacity));
}
.tr-border-violet-700 {
    --tw-border-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-border-opacity));
}
.tr-border-violet-800 {
    --tw-border-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-border-opacity));
}
.tr-border-violet-900 {
    --tw-border-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-border-opacity));
}
.tr-border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-border-opacity));
}
.tr-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-border-opacity));
}
.tr-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-border-opacity));
}
.tr-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity));
}
.tr-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity));
}
.tr-border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity));
}
.tr-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-border-opacity));
}
.tr-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity));
}
.tr-border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-border-opacity));
}
.tr-border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-border-opacity));
}
.tr-border-fuchsia-50 {
    --tw-border-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-border-opacity));
}
.tr-border-fuchsia-100 {
    --tw-border-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-border-opacity));
}
.tr-border-fuchsia-200 {
    --tw-border-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-border-opacity));
}
.tr-border-fuchsia-300 {
    --tw-border-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-border-opacity));
}
.tr-border-fuchsia-400 {
    --tw-border-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-border-opacity));
}
.tr-border-fuchsia-500 {
    --tw-border-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-border-opacity));
}
.tr-border-fuchsia-600 {
    --tw-border-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-border-opacity));
}
.tr-border-fuchsia-700 {
    --tw-border-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-border-opacity));
}
.tr-border-fuchsia-800 {
    --tw-border-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-border-opacity));
}
.tr-border-fuchsia-900 {
    --tw-border-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-border-opacity));
}
.tr-border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-border-opacity));
}
.tr-border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-border-opacity));
}
.tr-border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-border-opacity));
}
.tr-border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-border-opacity));
}
.tr-border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-border-opacity));
}
.tr-border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-border-opacity));
}
.tr-border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-border-opacity));
}
.tr-border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-border-opacity));
}
.tr-border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-border-opacity));
}
.tr-border-rose-50 {
    --tw-border-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-border-opacity));
}
.tr-border-rose-100 {
    --tw-border-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-border-opacity));
}
.tr-border-rose-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-border-opacity));
}
.tr-border-rose-300 {
    --tw-border-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-border-opacity));
}
.tr-border-rose-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-border-opacity));
}
.tr-border-rose-500 {
    --tw-border-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-border-opacity));
}
.tr-border-rose-600 {
    --tw-border-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-border-opacity));
}
.tr-border-rose-700 {
    --tw-border-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-border-opacity));
}
.tr-border-rose-800 {
    --tw-border-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-border-opacity));
}
.tr-border-rose-900 {
    --tw-border-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-border-opacity));
}
.tr-bg-inherit {
    background-color: inherit;
}
.tr-bg-transparent {
    background-color: transparent;
}
.tr-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.tr-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.tr-bg-slate-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.tr-bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.tr-bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}
.tr-bg-slate-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}
.tr-bg-slate-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}
.tr-bg-slate-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}
.tr-bg-slate-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}
.tr-bg-slate-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}
.tr-bg-slate-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}
.tr-bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
.tr-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.tr-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.tr-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.tr-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.tr-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
.tr-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.tr-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.tr-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.tr-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.tr-bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
.tr-bg-zinc-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.tr-bg-zinc-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}
.tr-bg-zinc-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}
.tr-bg-zinc-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}
.tr-bg-zinc-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}
.tr-bg-zinc-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}
.tr-bg-zinc-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}
.tr-bg-zinc-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}
.tr-bg-zinc-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}
.tr-bg-zinc-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}
.tr-bg-neutral-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.tr-bg-neutral-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.tr-bg-neutral-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}
.tr-bg-neutral-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}
.tr-bg-neutral-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}
.tr-bg-neutral-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}
.tr-bg-neutral-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}
.tr-bg-neutral-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}
.tr-bg-neutral-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.tr-bg-neutral-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}
.tr-bg-stone-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}
.tr-bg-stone-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}
.tr-bg-stone-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}
.tr-bg-stone-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}
.tr-bg-stone-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}
.tr-bg-stone-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}
.tr-bg-stone-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}
.tr-bg-stone-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}
.tr-bg-stone-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}
.tr-bg-stone-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}
.tr-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.tr-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.tr-bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}
.tr-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}
.tr-bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}
.tr-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.tr-bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
.tr-bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}
.tr-bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}
.tr-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}
.tr-bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}
.tr-bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}
.tr-bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}
.tr-bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}
.tr-bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}
.tr-bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}
.tr-bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}
.tr-bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}
.tr-bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(154 52 18 / var(--tw-bg-opacity));
}
.tr-bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(124 45 18 / var(--tw-bg-opacity));
}
.tr-bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}
.tr-bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}
.tr-bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}
.tr-bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}
.tr-bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}
.tr-bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}
.tr-bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}
.tr-bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}
.tr-bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}
.tr-bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}
.tr-bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}
.tr-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}
.tr-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}
.tr-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}
.tr-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}
.tr-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}
.tr-bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}
.tr-bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity));
}
.tr-bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(133 77 14 / var(--tw-bg-opacity));
}
.tr-bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(113 63 18 / var(--tw-bg-opacity));
}
.tr-bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}
.tr-bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}
.tr-bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}
.tr-bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}
.tr-bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}
.tr-bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}
.tr-bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}
.tr-bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}
.tr-bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}
.tr-bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}
.tr-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}
.tr-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}
.tr-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}
.tr-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}
.tr-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}
.tr-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
.tr-bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}
.tr-bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}
.tr-bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}
.tr-bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}
.tr-bg-emerald-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}
.tr-bg-emerald-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}
.tr-bg-emerald-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}
.tr-bg-emerald-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}
.tr-bg-emerald-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}
.tr-bg-emerald-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}
.tr-bg-emerald-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}
.tr-bg-emerald-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}
.tr-bg-emerald-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}
.tr-bg-emerald-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}
.tr-bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}
.tr-bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}
.tr-bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}
.tr-bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}
.tr-bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}
.tr-bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}
.tr-bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}
.tr-bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}
.tr-bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 94 89 / var(--tw-bg-opacity));
}
.tr-bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(19 78 74 / var(--tw-bg-opacity));
}
.tr-bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}
.tr-bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}
.tr-bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}
.tr-bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}
.tr-bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}
.tr-bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}
.tr-bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}
.tr-bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}
.tr-bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}
.tr-bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}
.tr-bg-sky-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}
.tr-bg-sky-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}
.tr-bg-sky-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}
.tr-bg-sky-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}
.tr-bg-sky-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}
.tr-bg-sky-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}
.tr-bg-sky-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}
.tr-bg-sky-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}
.tr-bg-sky-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}
.tr-bg-sky-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}
.tr-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.tr-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.tr-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}
.tr-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}
.tr-bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}
.tr-bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.tr-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.tr-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}
.tr-bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}
.tr-bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}
.tr-bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}
.tr-bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}
.tr-bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}
.tr-bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}
.tr-bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}
.tr-bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.tr-bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
.tr-bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}
.tr-bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}
.tr-bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}
.tr-bg-violet-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}
.tr-bg-violet-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}
.tr-bg-violet-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}
.tr-bg-violet-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}
.tr-bg-violet-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}
.tr-bg-violet-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}
.tr-bg-violet-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}
.tr-bg-violet-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}
.tr-bg-violet-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}
.tr-bg-violet-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}
.tr-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}
.tr-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}
.tr-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}
.tr-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}
.tr-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}
.tr-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}
.tr-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}
.tr-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}
.tr-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}
.tr-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}
.tr-bg-fuchsia-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}
.tr-bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}
.tr-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}
.tr-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}
.tr-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}
.tr-bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}
.tr-bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}
.tr-bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}
.tr-bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}
.tr-bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}
.tr-bg-rose-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}
.tr-bg-rose-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}
.tr-bg-rose-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}
.tr-bg-rose-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}
.tr-bg-rose-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}
.tr-bg-rose-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}
.tr-bg-rose-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}
.tr-bg-rose-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}
.tr-bg-rose-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}
.tr-bg-rose-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}
.tr-p-0 {
    padding: 0px;
}
.tr-pr-10 {
    padding-right: 2.5rem;
}
.tr-pl-0 {
    padding-left: 0px;
}
.tr-pt-0 {
    padding-top: 0px;
}
.tr-pr-0 {
    padding-right: 0px;
}
.tr-pb-0 {
    padding-bottom: 0px;
}
.tr-pl-px {
    padding-left: 1px;
}
.tr-pt-px {
    padding-top: 1px;
}
.tr-pr-px {
    padding-right: 1px;
}
.tr-pb-px {
    padding-bottom: 1px;
}
.tr-pl-0\.5 {
    padding-left: 0.125rem;
}
.tr-pt-0\.5 {
    padding-top: 0.125rem;
}
.tr-pr-0\.5 {
    padding-right: 0.125rem;
}
.tr-pb-0\.5 {
    padding-bottom: 0.125rem;
}
.tr-pl-1 {
    padding-left: 0.25rem;
}
.tr-pt-1 {
    padding-top: 0.25rem;
}
.tr-pr-1 {
    padding-right: 0.25rem;
}
.tr-pb-1 {
    padding-bottom: 0.25rem;
}
.tr-pl-1\.5 {
    padding-left: 0.375rem;
}
.tr-pt-1\.5 {
    padding-top: 0.375rem;
}
.tr-pr-1\.5 {
    padding-right: 0.375rem;
}
.tr-pb-1\.5 {
    padding-bottom: 0.375rem;
}
.tr-pl-2 {
    padding-left: 0.5rem;
}
.tr-pt-2 {
    padding-top: 0.5rem;
}
.tr-pr-2 {
    padding-right: 0.5rem;
}
.tr-pb-2 {
    padding-bottom: 0.5rem;
}
.tr-pl-2\.5 {
    padding-left: 0.625rem;
}
.tr-pt-2\.5 {
    padding-top: 0.625rem;
}
.tr-pr-2\.5 {
    padding-right: 0.625rem;
}
.tr-pb-2\.5 {
    padding-bottom: 0.625rem;
}
.tr-pl-3 {
    padding-left: 0.75rem;
}
.tr-pt-3 {
    padding-top: 0.75rem;
}
.tr-pr-3 {
    padding-right: 0.75rem;
}
.tr-pb-3 {
    padding-bottom: 0.75rem;
}
.tr-pl-3\.5 {
    padding-left: 0.875rem;
}
.tr-pt-3\.5 {
    padding-top: 0.875rem;
}
.tr-pr-3\.5 {
    padding-right: 0.875rem;
}
.tr-pb-3\.5 {
    padding-bottom: 0.875rem;
}
.tr-pl-4 {
    padding-left: 1rem;
}
.tr-pt-4 {
    padding-top: 1rem;
}
.tr-pr-4 {
    padding-right: 1rem;
}
.tr-pb-4 {
    padding-bottom: 1rem;
}
.tr-pl-6 {
    padding-left: 1.5rem;
}
.tr-pt-6 {
    padding-top: 1.5rem;
}
.tr-pr-6 {
    padding-right: 1.5rem;
}
.tr-pb-6 {
    padding-bottom: 1.5rem;
}
.tr-pl-8 {
    padding-left: 2rem;
}
.tr-pt-8 {
    padding-top: 2rem;
}
.tr-pr-8 {
    padding-right: 2rem;
}
.tr-pb-8 {
    padding-bottom: 2rem;
}
.tr-text-left {
    text-align: left;
}
.tr-text-center {
    text-align: center;
}
.tr-text-right {
    text-align: right;
}
.tr-text-justify {
    text-align: justify;
}
.tr-align-top {
    vertical-align: top;
}
.tr-align-middle {
    vertical-align: middle;
}
.tr-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.tr-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.tr-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.tr-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.tr-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.tr-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.tr-font-medium {
    font-weight: 500;
}
.tr-font-normal {
    font-weight: 400;
}
.tr-font-semibold {
    font-weight: 600;
}
.tr-italic {
    font-style: italic;
}
.tr-tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.tr-text-inherit {
    color: inherit;
}
.tr-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
}
.tr-text-transparent {
    color: transparent;
}
.tr-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.tr-text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
.tr-text-slate-50 {
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity));
}
.tr-text-slate-100 {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity));
}
.tr-text-slate-200 {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}
.tr-text-slate-300 {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity));
}
.tr-text-slate-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity));
}
.tr-text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity));
}
.tr-text-slate-600 {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity));
}
.tr-text-slate-700 {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity));
}
.tr-text-slate-800 {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity));
}
.tr-text-slate-900 {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity));
}
.tr-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity));
}
.tr-text-gray-100 {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity));
}
.tr-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
}
.tr-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
}
.tr-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
}
.tr-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.tr-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}
.tr-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}
.tr-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
}
.tr-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
}
.tr-text-zinc-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity));
}
.tr-text-zinc-100 {
    --tw-text-opacity: 1;
    color: rgb(244 244 245 / var(--tw-text-opacity));
}
.tr-text-zinc-200 {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity));
}
.tr-text-zinc-300 {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity));
}
.tr-text-zinc-400 {
    --tw-text-opacity: 1;
    color: rgb(161 161 170 / var(--tw-text-opacity));
}
.tr-text-zinc-500 {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity));
}
.tr-text-zinc-600 {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity));
}
.tr-text-zinc-700 {
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity));
}
.tr-text-zinc-800 {
    --tw-text-opacity: 1;
    color: rgb(39 39 42 / var(--tw-text-opacity));
}
.tr-text-zinc-900 {
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity));
}
.tr-text-neutral-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity));
}
.tr-text-neutral-100 {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity));
}
.tr-text-neutral-200 {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity));
}
.tr-text-neutral-300 {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity));
}
.tr-text-neutral-400 {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity));
}
.tr-text-neutral-500 {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity));
}
.tr-text-neutral-600 {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity));
}
.tr-text-neutral-700 {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity));
}
.tr-text-neutral-800 {
    --tw-text-opacity: 1;
    color: rgb(38 38 38 / var(--tw-text-opacity));
}
.tr-text-neutral-900 {
    --tw-text-opacity: 1;
    color: rgb(23 23 23 / var(--tw-text-opacity));
}
.tr-text-stone-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 249 / var(--tw-text-opacity));
}
.tr-text-stone-100 {
    --tw-text-opacity: 1;
    color: rgb(245 245 244 / var(--tw-text-opacity));
}
.tr-text-stone-200 {
    --tw-text-opacity: 1;
    color: rgb(231 229 228 / var(--tw-text-opacity));
}
.tr-text-stone-300 {
    --tw-text-opacity: 1;
    color: rgb(214 211 209 / var(--tw-text-opacity));
}
.tr-text-stone-400 {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity));
}
.tr-text-stone-500 {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity));
}
.tr-text-stone-600 {
    --tw-text-opacity: 1;
    color: rgb(87 83 78 / var(--tw-text-opacity));
}
.tr-text-stone-700 {
    --tw-text-opacity: 1;
    color: rgb(68 64 60 / var(--tw-text-opacity));
}
.tr-text-stone-800 {
    --tw-text-opacity: 1;
    color: rgb(41 37 36 / var(--tw-text-opacity));
}
.tr-text-stone-900 {
    --tw-text-opacity: 1;
    color: rgb(28 25 23 / var(--tw-text-opacity));
}
.tr-text-red-50 {
    --tw-text-opacity: 1;
    color: rgb(254 242 242 / var(--tw-text-opacity));
}
.tr-text-red-100 {
    --tw-text-opacity: 1;
    color: rgb(254 226 226 / var(--tw-text-opacity));
}
.tr-text-red-200 {
    --tw-text-opacity: 1;
    color: rgb(254 202 202 / var(--tw-text-opacity));
}
.tr-text-red-300 {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity));
}
.tr-text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
}
.tr-text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}
.tr-text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity));
}
.tr-text-red-700 {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity));
}
.tr-text-red-800 {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity));
}
.tr-text-red-900 {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity));
}
.tr-text-orange-50 {
    --tw-text-opacity: 1;
    color: rgb(255 247 237 / var(--tw-text-opacity));
}
.tr-text-orange-100 {
    --tw-text-opacity: 1;
    color: rgb(255 237 213 / var(--tw-text-opacity));
}
.tr-text-orange-200 {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity));
}
.tr-text-orange-300 {
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity));
}
.tr-text-orange-400 {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity));
}
.tr-text-orange-500 {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity));
}
.tr-text-orange-600 {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity));
}
.tr-text-orange-700 {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity));
}
.tr-text-orange-800 {
    --tw-text-opacity: 1;
    color: rgb(154 52 18 / var(--tw-text-opacity));
}
.tr-text-orange-900 {
    --tw-text-opacity: 1;
    color: rgb(124 45 18 / var(--tw-text-opacity));
}
.tr-text-amber-50 {
    --tw-text-opacity: 1;
    color: rgb(255 251 235 / var(--tw-text-opacity));
}
.tr-text-amber-100 {
    --tw-text-opacity: 1;
    color: rgb(254 243 199 / var(--tw-text-opacity));
}
.tr-text-amber-200 {
    --tw-text-opacity: 1;
    color: rgb(253 230 138 / var(--tw-text-opacity));
}
.tr-text-amber-300 {
    --tw-text-opacity: 1;
    color: rgb(252 211 77 / var(--tw-text-opacity));
}
.tr-text-amber-400 {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity));
}
.tr-text-amber-500 {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity));
}
.tr-text-amber-600 {
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity));
}
.tr-text-amber-700 {
    --tw-text-opacity: 1;
    color: rgb(180 83 9 / var(--tw-text-opacity));
}
.tr-text-amber-800 {
    --tw-text-opacity: 1;
    color: rgb(146 64 14 / var(--tw-text-opacity));
}
.tr-text-amber-900 {
    --tw-text-opacity: 1;
    color: rgb(120 53 15 / var(--tw-text-opacity));
}
.tr-text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgb(254 252 232 / var(--tw-text-opacity));
}
.tr-text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgb(254 249 195 / var(--tw-text-opacity));
}
.tr-text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgb(254 240 138 / var(--tw-text-opacity));
}
.tr-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity));
}
.tr-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity));
}
.tr-text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity));
}
.tr-text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity));
}
.tr-text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgb(161 98 7 / var(--tw-text-opacity));
}
.tr-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgb(133 77 14 / var(--tw-text-opacity));
}
.tr-text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgb(113 63 18 / var(--tw-text-opacity));
}
.tr-text-lime-50 {
    --tw-text-opacity: 1;
    color: rgb(247 254 231 / var(--tw-text-opacity));
}
.tr-text-lime-100 {
    --tw-text-opacity: 1;
    color: rgb(236 252 203 / var(--tw-text-opacity));
}
.tr-text-lime-200 {
    --tw-text-opacity: 1;
    color: rgb(217 249 157 / var(--tw-text-opacity));
}
.tr-text-lime-300 {
    --tw-text-opacity: 1;
    color: rgb(190 242 100 / var(--tw-text-opacity));
}
.tr-text-lime-400 {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity));
}
.tr-text-lime-500 {
    --tw-text-opacity: 1;
    color: rgb(132 204 22 / var(--tw-text-opacity));
}
.tr-text-lime-600 {
    --tw-text-opacity: 1;
    color: rgb(101 163 13 / var(--tw-text-opacity));
}
.tr-text-lime-700 {
    --tw-text-opacity: 1;
    color: rgb(77 124 15 / var(--tw-text-opacity));
}
.tr-text-lime-800 {
    --tw-text-opacity: 1;
    color: rgb(63 98 18 / var(--tw-text-opacity));
}
.tr-text-lime-900 {
    --tw-text-opacity: 1;
    color: rgb(54 83 20 / var(--tw-text-opacity));
}
.tr-text-green-50 {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity));
}
.tr-text-green-100 {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity));
}
.tr-text-green-200 {
    --tw-text-opacity: 1;
    color: rgb(187 247 208 / var(--tw-text-opacity));
}
.tr-text-green-300 {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity));
}
.tr-text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
}
.tr-text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
}
.tr-text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity));
}
.tr-text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity));
}
.tr-text-green-800 {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity));
}
.tr-text-green-900 {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity));
}
.tr-text-emerald-50 {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity));
}
.tr-text-emerald-100 {
    --tw-text-opacity: 1;
    color: rgb(209 250 229 / var(--tw-text-opacity));
}
.tr-text-emerald-200 {
    --tw-text-opacity: 1;
    color: rgb(167 243 208 / var(--tw-text-opacity));
}
.tr-text-emerald-300 {
    --tw-text-opacity: 1;
    color: rgb(110 231 183 / var(--tw-text-opacity));
}
.tr-text-emerald-400 {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity));
}
.tr-text-emerald-500 {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity));
}
.tr-text-emerald-600 {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity));
}
.tr-text-emerald-700 {
    --tw-text-opacity: 1;
    color: rgb(4 120 87 / var(--tw-text-opacity));
}
.tr-text-emerald-800 {
    --tw-text-opacity: 1;
    color: rgb(6 95 70 / var(--tw-text-opacity));
}
.tr-text-emerald-900 {
    --tw-text-opacity: 1;
    color: rgb(6 78 59 / var(--tw-text-opacity));
}
.tr-text-teal-50 {
    --tw-text-opacity: 1;
    color: rgb(240 253 250 / var(--tw-text-opacity));
}
.tr-text-teal-100 {
    --tw-text-opacity: 1;
    color: rgb(204 251 241 / var(--tw-text-opacity));
}
.tr-text-teal-200 {
    --tw-text-opacity: 1;
    color: rgb(153 246 228 / var(--tw-text-opacity));
}
.tr-text-teal-300 {
    --tw-text-opacity: 1;
    color: rgb(94 234 212 / var(--tw-text-opacity));
}
.tr-text-teal-400 {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity));
}
.tr-text-teal-500 {
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity));
}
.tr-text-teal-600 {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity));
}
.tr-text-teal-700 {
    --tw-text-opacity: 1;
    color: rgb(15 118 110 / var(--tw-text-opacity));
}
.tr-text-teal-800 {
    --tw-text-opacity: 1;
    color: rgb(17 94 89 / var(--tw-text-opacity));
}
.tr-text-teal-900 {
    --tw-text-opacity: 1;
    color: rgb(19 78 74 / var(--tw-text-opacity));
}
.tr-text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgb(236 254 255 / var(--tw-text-opacity));
}
.tr-text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgb(207 250 254 / var(--tw-text-opacity));
}
.tr-text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgb(165 243 252 / var(--tw-text-opacity));
}
.tr-text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgb(103 232 249 / var(--tw-text-opacity));
}
.tr-text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity));
}
.tr-text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity));
}
.tr-text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgb(8 145 178 / var(--tw-text-opacity));
}
.tr-text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity));
}
.tr-text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgb(21 94 117 / var(--tw-text-opacity));
}
.tr-text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgb(22 78 99 / var(--tw-text-opacity));
}
.tr-text-sky-50 {
    --tw-text-opacity: 1;
    color: rgb(240 249 255 / var(--tw-text-opacity));
}
.tr-text-sky-100 {
    --tw-text-opacity: 1;
    color: rgb(224 242 254 / var(--tw-text-opacity));
}
.tr-text-sky-200 {
    --tw-text-opacity: 1;
    color: rgb(186 230 253 / var(--tw-text-opacity));
}
.tr-text-sky-300 {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity));
}
.tr-text-sky-400 {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity));
}
.tr-text-sky-500 {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity));
}
.tr-text-sky-600 {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity));
}
.tr-text-sky-700 {
    --tw-text-opacity: 1;
    color: rgb(3 105 161 / var(--tw-text-opacity));
}
.tr-text-sky-800 {
    --tw-text-opacity: 1;
    color: rgb(7 89 133 / var(--tw-text-opacity));
}
.tr-text-sky-900 {
    --tw-text-opacity: 1;
    color: rgb(12 74 110 / var(--tw-text-opacity));
}
.tr-text-blue-50 {
    --tw-text-opacity: 1;
    color: rgb(239 246 255 / var(--tw-text-opacity));
}
.tr-text-blue-100 {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity));
}
.tr-text-blue-200 {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity));
}
.tr-text-blue-300 {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity));
}
.tr-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity));
}
.tr-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
}
.tr-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity));
}
.tr-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity));
}
.tr-text-blue-900 {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity));
}
.tr-text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity));
}
.tr-text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity));
}
.tr-text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgb(199 210 254 / var(--tw-text-opacity));
}
.tr-text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity));
}
.tr-text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity));
}
.tr-text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity));
}
.tr-text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
}
.tr-text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity));
}
.tr-text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgb(55 48 163 / var(--tw-text-opacity));
}
.tr-text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgb(49 46 129 / var(--tw-text-opacity));
}
.tr-text-violet-50 {
    --tw-text-opacity: 1;
    color: rgb(245 243 255 / var(--tw-text-opacity));
}
.tr-text-violet-100 {
    --tw-text-opacity: 1;
    color: rgb(237 233 254 / var(--tw-text-opacity));
}
.tr-text-violet-200 {
    --tw-text-opacity: 1;
    color: rgb(221 214 254 / var(--tw-text-opacity));
}
.tr-text-violet-300 {
    --tw-text-opacity: 1;
    color: rgb(196 181 253 / var(--tw-text-opacity));
}
.tr-text-violet-400 {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity));
}
.tr-text-violet-500 {
    --tw-text-opacity: 1;
    color: rgb(139 92 246 / var(--tw-text-opacity));
}
.tr-text-violet-600 {
    --tw-text-opacity: 1;
    color: rgb(124 58 237 / var(--tw-text-opacity));
}
.tr-text-violet-700 {
    --tw-text-opacity: 1;
    color: rgb(109 40 217 / var(--tw-text-opacity));
}
.tr-text-violet-800 {
    --tw-text-opacity: 1;
    color: rgb(91 33 182 / var(--tw-text-opacity));
}
.tr-text-violet-900 {
    --tw-text-opacity: 1;
    color: rgb(76 29 149 / var(--tw-text-opacity));
}
.tr-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgb(250 245 255 / var(--tw-text-opacity));
}
.tr-text-purple-100 {
    --tw-text-opacity: 1;
    color: rgb(243 232 255 / var(--tw-text-opacity));
}
.tr-text-purple-200 {
    --tw-text-opacity: 1;
    color: rgb(233 213 255 / var(--tw-text-opacity));
}
.tr-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity));
}
.tr-text-purple-400 {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity));
}
.tr-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity));
}
.tr-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity));
}
.tr-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity));
}
.tr-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity));
}
.tr-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity));
}
.tr-text-fuchsia-50 {
    --tw-text-opacity: 1;
    color: rgb(253 244 255 / var(--tw-text-opacity));
}
.tr-text-fuchsia-100 {
    --tw-text-opacity: 1;
    color: rgb(250 232 255 / var(--tw-text-opacity));
}
.tr-text-fuchsia-200 {
    --tw-text-opacity: 1;
    color: rgb(245 208 254 / var(--tw-text-opacity));
}
.tr-text-fuchsia-300 {
    --tw-text-opacity: 1;
    color: rgb(240 171 252 / var(--tw-text-opacity));
}
.tr-text-fuchsia-400 {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity));
}
.tr-text-fuchsia-500 {
    --tw-text-opacity: 1;
    color: rgb(217 70 239 / var(--tw-text-opacity));
}
.tr-text-fuchsia-600 {
    --tw-text-opacity: 1;
    color: rgb(192 38 211 / var(--tw-text-opacity));
}
.tr-text-fuchsia-700 {
    --tw-text-opacity: 1;
    color: rgb(162 28 175 / var(--tw-text-opacity));
}
.tr-text-fuchsia-800 {
    --tw-text-opacity: 1;
    color: rgb(134 25 143 / var(--tw-text-opacity));
}
.tr-text-fuchsia-900 {
    --tw-text-opacity: 1;
    color: rgb(112 26 117 / var(--tw-text-opacity));
}
.tr-text-pink-50 {
    --tw-text-opacity: 1;
    color: rgb(253 242 248 / var(--tw-text-opacity));
}
.tr-text-pink-100 {
    --tw-text-opacity: 1;
    color: rgb(252 231 243 / var(--tw-text-opacity));
}
.tr-text-pink-200 {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity));
}
.tr-text-pink-300 {
    --tw-text-opacity: 1;
    color: rgb(249 168 212 / var(--tw-text-opacity));
}
.tr-text-pink-400 {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity));
}
.tr-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity));
}
.tr-text-pink-600 {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity));
}
.tr-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgb(190 24 93 / var(--tw-text-opacity));
}
.tr-text-pink-900 {
    --tw-text-opacity: 1;
    color: rgb(131 24 67 / var(--tw-text-opacity));
}
.tr-text-rose-50 {
    --tw-text-opacity: 1;
    color: rgb(255 241 242 / var(--tw-text-opacity));
}
.tr-text-rose-100 {
    --tw-text-opacity: 1;
    color: rgb(255 228 230 / var(--tw-text-opacity));
}
.tr-text-rose-200 {
    --tw-text-opacity: 1;
    color: rgb(254 205 211 / var(--tw-text-opacity));
}
.tr-text-rose-300 {
    --tw-text-opacity: 1;
    color: rgb(253 164 175 / var(--tw-text-opacity));
}
.tr-text-rose-400 {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity));
}
.tr-text-rose-500 {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity));
}
.tr-text-rose-600 {
    --tw-text-opacity: 1;
    color: rgb(225 29 72 / var(--tw-text-opacity));
}
.tr-text-rose-700 {
    --tw-text-opacity: 1;
    color: rgb(190 18 60 / var(--tw-text-opacity));
}
.tr-text-rose-800 {
    --tw-text-opacity: 1;
    color: rgb(159 18 57 / var(--tw-text-opacity));
}
.tr-text-rose-900 {
    --tw-text-opacity: 1;
    color: rgb(136 19 55 / var(--tw-text-opacity));
}
.tr-no-underline {
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}
.tr-opacity-50 {
    opacity: 0.5;
}
.tr-opacity-70 {
    opacity: 0.7;
}
.tr-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tr-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tr-shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tr-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tr-outline-transparent {
    outline-color: transparent;
}
.tr-outline-white {
    outline-color: #fff;
}
.tr-outline-black {
    outline-color: #000;
}
.tr-outline-slate-50 {
    outline-color: #f8fafc;
}
.tr-outline-slate-100 {
    outline-color: #f1f5f9;
}
.tr-outline-slate-200 {
    outline-color: #e2e8f0;
}
.tr-outline-slate-300 {
    outline-color: #cbd5e1;
}
.tr-outline-slate-400 {
    outline-color: #94a3b8;
}
.tr-outline-slate-500 {
    outline-color: #64748b;
}
.tr-outline-slate-600 {
    outline-color: #475569;
}
.tr-outline-slate-700 {
    outline-color: #334155;
}
.tr-outline-slate-800 {
    outline-color: #1e293b;
}
.tr-outline-slate-900 {
    outline-color: #0f172a;
}
.tr-outline-gray-50 {
    outline-color: #f9fafb;
}
.tr-outline-gray-100 {
    outline-color: #f3f4f6;
}
.tr-outline-gray-200 {
    outline-color: #e5e7eb;
}
.tr-outline-gray-300 {
    outline-color: #d1d5db;
}
.tr-outline-gray-400 {
    outline-color: #9ca3af;
}
.tr-outline-gray-500 {
    outline-color: #6b7280;
}
.tr-outline-gray-600 {
    outline-color: #4b5563;
}
.tr-outline-gray-700 {
    outline-color: #374151;
}
.tr-outline-gray-800 {
    outline-color: #1f2937;
}
.tr-outline-gray-900 {
    outline-color: #111827;
}
.tr-outline-zinc-50 {
    outline-color: #fafafa;
}
.tr-outline-zinc-100 {
    outline-color: #f4f4f5;
}
.tr-outline-zinc-200 {
    outline-color: #e4e4e7;
}
.tr-outline-zinc-300 {
    outline-color: #d4d4d8;
}
.tr-outline-zinc-400 {
    outline-color: #a1a1aa;
}
.tr-outline-zinc-500 {
    outline-color: #71717a;
}
.tr-outline-zinc-600 {
    outline-color: #52525b;
}
.tr-outline-zinc-700 {
    outline-color: #3f3f46;
}
.tr-outline-zinc-800 {
    outline-color: #27272a;
}
.tr-outline-zinc-900 {
    outline-color: #18181b;
}
.tr-outline-neutral-50 {
    outline-color: #fafafa;
}
.tr-outline-neutral-100 {
    outline-color: #f5f5f5;
}
.tr-outline-neutral-200 {
    outline-color: #e5e5e5;
}
.tr-outline-neutral-300 {
    outline-color: #d4d4d4;
}
.tr-outline-neutral-400 {
    outline-color: #a3a3a3;
}
.tr-outline-neutral-500 {
    outline-color: #737373;
}
.tr-outline-neutral-600 {
    outline-color: #525252;
}
.tr-outline-neutral-700 {
    outline-color: #404040;
}
.tr-outline-neutral-800 {
    outline-color: #262626;
}
.tr-outline-neutral-900 {
    outline-color: #171717;
}
.tr-outline-stone-50 {
    outline-color: #fafaf9;
}
.tr-outline-stone-100 {
    outline-color: #f5f5f4;
}
.tr-outline-stone-200 {
    outline-color: #e7e5e4;
}
.tr-outline-stone-300 {
    outline-color: #d6d3d1;
}
.tr-outline-stone-400 {
    outline-color: #a8a29e;
}
.tr-outline-stone-500 {
    outline-color: #78716c;
}
.tr-outline-stone-600 {
    outline-color: #57534e;
}
.tr-outline-stone-700 {
    outline-color: #44403c;
}
.tr-outline-stone-800 {
    outline-color: #292524;
}
.tr-outline-stone-900 {
    outline-color: #1c1917;
}
.tr-outline-red-50 {
    outline-color: #fef2f2;
}
.tr-outline-red-100 {
    outline-color: #fee2e2;
}
.tr-outline-red-200 {
    outline-color: #fecaca;
}
.tr-outline-red-300 {
    outline-color: #fca5a5;
}
.tr-outline-red-400 {
    outline-color: #f87171;
}
.tr-outline-red-500 {
    outline-color: #ef4444;
}
.tr-outline-red-600 {
    outline-color: #dc2626;
}
.tr-outline-red-700 {
    outline-color: #b91c1c;
}
.tr-outline-red-800 {
    outline-color: #991b1b;
}
.tr-outline-red-900 {
    outline-color: #7f1d1d;
}
.tr-outline-orange-50 {
    outline-color: #fff7ed;
}
.tr-outline-orange-100 {
    outline-color: #ffedd5;
}
.tr-outline-orange-200 {
    outline-color: #fed7aa;
}
.tr-outline-orange-300 {
    outline-color: #fdba74;
}
.tr-outline-orange-400 {
    outline-color: #fb923c;
}
.tr-outline-orange-500 {
    outline-color: #f97316;
}
.tr-outline-orange-600 {
    outline-color: #ea580c;
}
.tr-outline-orange-700 {
    outline-color: #c2410c;
}
.tr-outline-orange-800 {
    outline-color: #9a3412;
}
.tr-outline-orange-900 {
    outline-color: #7c2d12;
}
.tr-outline-amber-50 {
    outline-color: #fffbeb;
}
.tr-outline-amber-100 {
    outline-color: #fef3c7;
}
.tr-outline-amber-200 {
    outline-color: #fde68a;
}
.tr-outline-amber-300 {
    outline-color: #fcd34d;
}
.tr-outline-amber-400 {
    outline-color: #fbbf24;
}
.tr-outline-amber-500 {
    outline-color: #f59e0b;
}
.tr-outline-amber-600 {
    outline-color: #d97706;
}
.tr-outline-amber-700 {
    outline-color: #b45309;
}
.tr-outline-amber-800 {
    outline-color: #92400e;
}
.tr-outline-amber-900 {
    outline-color: #78350f;
}
.tr-outline-yellow-50 {
    outline-color: #fefce8;
}
.tr-outline-yellow-100 {
    outline-color: #fef9c3;
}
.tr-outline-yellow-200 {
    outline-color: #fef08a;
}
.tr-outline-yellow-300 {
    outline-color: #fde047;
}
.tr-outline-yellow-400 {
    outline-color: #facc15;
}
.tr-outline-yellow-500 {
    outline-color: #eab308;
}
.tr-outline-yellow-600 {
    outline-color: #ca8a04;
}
.tr-outline-yellow-700 {
    outline-color: #a16207;
}
.tr-outline-yellow-800 {
    outline-color: #854d0e;
}
.tr-outline-yellow-900 {
    outline-color: #713f12;
}
.tr-outline-lime-50 {
    outline-color: #f7fee7;
}
.tr-outline-lime-100 {
    outline-color: #ecfccb;
}
.tr-outline-lime-200 {
    outline-color: #d9f99d;
}
.tr-outline-lime-300 {
    outline-color: #bef264;
}
.tr-outline-lime-400 {
    outline-color: #a3e635;
}
.tr-outline-lime-500 {
    outline-color: #84cc16;
}
.tr-outline-lime-600 {
    outline-color: #65a30d;
}
.tr-outline-lime-700 {
    outline-color: #4d7c0f;
}
.tr-outline-lime-800 {
    outline-color: #3f6212;
}
.tr-outline-lime-900 {
    outline-color: #365314;
}
.tr-outline-green-50 {
    outline-color: #f0fdf4;
}
.tr-outline-green-100 {
    outline-color: #dcfce7;
}
.tr-outline-green-200 {
    outline-color: #bbf7d0;
}
.tr-outline-green-300 {
    outline-color: #86efac;
}
.tr-outline-green-400 {
    outline-color: #4ade80;
}
.tr-outline-green-500 {
    outline-color: #22c55e;
}
.tr-outline-green-600 {
    outline-color: #16a34a;
}
.tr-outline-green-700 {
    outline-color: #15803d;
}
.tr-outline-green-800 {
    outline-color: #166534;
}
.tr-outline-green-900 {
    outline-color: #14532d;
}
.tr-outline-emerald-50 {
    outline-color: #ecfdf5;
}
.tr-outline-emerald-100 {
    outline-color: #d1fae5;
}
.tr-outline-emerald-200 {
    outline-color: #a7f3d0;
}
.tr-outline-emerald-300 {
    outline-color: #6ee7b7;
}
.tr-outline-emerald-400 {
    outline-color: #34d399;
}
.tr-outline-emerald-500 {
    outline-color: #10b981;
}
.tr-outline-emerald-600 {
    outline-color: #059669;
}
.tr-outline-emerald-700 {
    outline-color: #047857;
}
.tr-outline-emerald-800 {
    outline-color: #065f46;
}
.tr-outline-emerald-900 {
    outline-color: #064e3b;
}
.tr-outline-teal-50 {
    outline-color: #f0fdfa;
}
.tr-outline-teal-100 {
    outline-color: #ccfbf1;
}
.tr-outline-teal-200 {
    outline-color: #99f6e4;
}
.tr-outline-teal-300 {
    outline-color: #5eead4;
}
.tr-outline-teal-400 {
    outline-color: #2dd4bf;
}
.tr-outline-teal-500 {
    outline-color: #14b8a6;
}
.tr-outline-teal-600 {
    outline-color: #0d9488;
}
.tr-outline-teal-700 {
    outline-color: #0f766e;
}
.tr-outline-teal-800 {
    outline-color: #115e59;
}
.tr-outline-teal-900 {
    outline-color: #134e4a;
}
.tr-outline-cyan-50 {
    outline-color: #ecfeff;
}
.tr-outline-cyan-100 {
    outline-color: #cffafe;
}
.tr-outline-cyan-200 {
    outline-color: #a5f3fc;
}
.tr-outline-cyan-300 {
    outline-color: #67e8f9;
}
.tr-outline-cyan-400 {
    outline-color: #22d3ee;
}
.tr-outline-cyan-500 {
    outline-color: #06b6d4;
}
.tr-outline-cyan-600 {
    outline-color: #0891b2;
}
.tr-outline-cyan-700 {
    outline-color: #0e7490;
}
.tr-outline-cyan-800 {
    outline-color: #155e75;
}
.tr-outline-cyan-900 {
    outline-color: #164e63;
}
.tr-outline-sky-50 {
    outline-color: #f0f9ff;
}
.tr-outline-sky-100 {
    outline-color: #e0f2fe;
}
.tr-outline-sky-200 {
    outline-color: #bae6fd;
}
.tr-outline-sky-300 {
    outline-color: #7dd3fc;
}
.tr-outline-sky-400 {
    outline-color: #38bdf8;
}
.tr-outline-sky-500 {
    outline-color: #0ea5e9;
}
.tr-outline-sky-600 {
    outline-color: #0284c7;
}
.tr-outline-sky-700 {
    outline-color: #0369a1;
}
.tr-outline-sky-800 {
    outline-color: #075985;
}
.tr-outline-sky-900 {
    outline-color: #0c4a6e;
}
.tr-outline-blue-50 {
    outline-color: #eff6ff;
}
.tr-outline-blue-100 {
    outline-color: #dbeafe;
}
.tr-outline-blue-200 {
    outline-color: #bfdbfe;
}
.tr-outline-blue-300 {
    outline-color: #93c5fd;
}
.tr-outline-blue-400 {
    outline-color: #60a5fa;
}
.tr-outline-blue-500 {
    outline-color: #3b82f6;
}
.tr-outline-blue-600 {
    outline-color: #2563eb;
}
.tr-outline-blue-700 {
    outline-color: #1d4ed8;
}
.tr-outline-blue-800 {
    outline-color: #1e40af;
}
.tr-outline-blue-900 {
    outline-color: #1e3a8a;
}
.tr-outline-indigo-50 {
    outline-color: #eef2ff;
}
.tr-outline-indigo-100 {
    outline-color: #e0e7ff;
}
.tr-outline-indigo-200 {
    outline-color: #c7d2fe;
}
.tr-outline-indigo-300 {
    outline-color: #a5b4fc;
}
.tr-outline-indigo-400 {
    outline-color: #818cf8;
}
.tr-outline-indigo-500 {
    outline-color: #6366f1;
}
.tr-outline-indigo-600 {
    outline-color: #4f46e5;
}
.tr-outline-indigo-700 {
    outline-color: #4338ca;
}
.tr-outline-indigo-800 {
    outline-color: #3730a3;
}
.tr-outline-indigo-900 {
    outline-color: #312e81;
}
.tr-outline-violet-50 {
    outline-color: #f5f3ff;
}
.tr-outline-violet-100 {
    outline-color: #ede9fe;
}
.tr-outline-violet-200 {
    outline-color: #ddd6fe;
}
.tr-outline-violet-300 {
    outline-color: #c4b5fd;
}
.tr-outline-violet-400 {
    outline-color: #a78bfa;
}
.tr-outline-violet-500 {
    outline-color: #8b5cf6;
}
.tr-outline-violet-600 {
    outline-color: #7c3aed;
}
.tr-outline-violet-700 {
    outline-color: #6d28d9;
}
.tr-outline-violet-800 {
    outline-color: #5b21b6;
}
.tr-outline-violet-900 {
    outline-color: #4c1d95;
}
.tr-outline-purple-50 {
    outline-color: #faf5ff;
}
.tr-outline-purple-100 {
    outline-color: #f3e8ff;
}
.tr-outline-purple-200 {
    outline-color: #e9d5ff;
}
.tr-outline-purple-300 {
    outline-color: #d8b4fe;
}
.tr-outline-purple-400 {
    outline-color: #c084fc;
}
.tr-outline-purple-500 {
    outline-color: #a855f7;
}
.tr-outline-purple-600 {
    outline-color: #9333ea;
}
.tr-outline-purple-700 {
    outline-color: #7e22ce;
}
.tr-outline-purple-800 {
    outline-color: #6b21a8;
}
.tr-outline-purple-900 {
    outline-color: #581c87;
}
.tr-outline-fuchsia-50 {
    outline-color: #fdf4ff;
}
.tr-outline-fuchsia-100 {
    outline-color: #fae8ff;
}
.tr-outline-fuchsia-200 {
    outline-color: #f5d0fe;
}
.tr-outline-fuchsia-300 {
    outline-color: #f0abfc;
}
.tr-outline-fuchsia-400 {
    outline-color: #e879f9;
}
.tr-outline-fuchsia-500 {
    outline-color: #d946ef;
}
.tr-outline-fuchsia-600 {
    outline-color: #c026d3;
}
.tr-outline-fuchsia-700 {
    outline-color: #a21caf;
}
.tr-outline-fuchsia-800 {
    outline-color: #86198f;
}
.tr-outline-fuchsia-900 {
    outline-color: #701a75;
}
.tr-outline-pink-50 {
    outline-color: #fdf2f8;
}
.tr-outline-pink-100 {
    outline-color: #fce7f3;
}
.tr-outline-pink-200 {
    outline-color: #fbcfe8;
}
.tr-outline-pink-300 {
    outline-color: #f9a8d4;
}
.tr-outline-pink-400 {
    outline-color: #f472b6;
}
.tr-outline-pink-500 {
    outline-color: #ec4899;
}
.tr-outline-pink-600 {
    outline-color: #db2777;
}
.tr-outline-pink-700 {
    outline-color: #be185d;
}
.tr-outline-pink-900 {
    outline-color: #831843;
}
.tr-outline-rose-50 {
    outline-color: #fff1f2;
}
.tr-outline-rose-100 {
    outline-color: #ffe4e6;
}
.tr-outline-rose-200 {
    outline-color: #fecdd3;
}
.tr-outline-rose-300 {
    outline-color: #fda4af;
}
.tr-outline-rose-400 {
    outline-color: #fb7185;
}
.tr-outline-rose-500 {
    outline-color: #f43f5e;
}
.tr-outline-rose-600 {
    outline-color: #e11d48;
}
.tr-outline-rose-700 {
    outline-color: #be123c;
}
.tr-outline-rose-800 {
    outline-color: #9f1239;
}
.tr-outline-rose-900 {
    outline-color: #881337;
}
.tr-ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.tr-ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.tr-ring-transparent {
    --tw-ring-color: transparent;
}
.tr-ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}
.tr-ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}
.tr-ring-slate-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity));
}
.tr-ring-slate-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity));
}
.tr-ring-slate-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity));
}
.tr-ring-slate-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity));
}
.tr-ring-slate-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity));
}
.tr-ring-slate-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
}
.tr-ring-slate-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity));
}
.tr-ring-slate-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity));
}
.tr-ring-slate-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity));
}
.tr-ring-slate-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 23 42 / var(--tw-ring-opacity));
}
.tr-ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity));
}
.tr-ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}
.tr-ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}
.tr-ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}
.tr-ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity));
}
.tr-ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
}
.tr-ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}
.tr-ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
}
.tr-ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}
.tr-ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}
.tr-ring-zinc-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity));
}
.tr-ring-zinc-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 244 245 / var(--tw-ring-opacity));
}
.tr-ring-zinc-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(228 228 231 / var(--tw-ring-opacity));
}
.tr-ring-zinc-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 216 / var(--tw-ring-opacity));
}
.tr-ring-zinc-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 161 170 / var(--tw-ring-opacity));
}
.tr-ring-zinc-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 113 122 / var(--tw-ring-opacity));
}
.tr-ring-zinc-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity));
}
.tr-ring-zinc-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 63 70 / var(--tw-ring-opacity));
}
.tr-ring-zinc-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(39 39 42 / var(--tw-ring-opacity));
}
.tr-ring-zinc-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(24 24 27 / var(--tw-ring-opacity));
}
.tr-ring-neutral-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity));
}
.tr-ring-neutral-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 245 / var(--tw-ring-opacity));
}
.tr-ring-neutral-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity));
}
.tr-ring-neutral-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity));
}
.tr-ring-neutral-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 163 163 / var(--tw-ring-opacity));
}
.tr-ring-neutral-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(115 115 115 / var(--tw-ring-opacity));
}
.tr-ring-neutral-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity));
}
.tr-ring-neutral-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(64 64 64 / var(--tw-ring-opacity));
}
.tr-ring-neutral-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity));
}
.tr-ring-neutral-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(23 23 23 / var(--tw-ring-opacity));
}
.tr-ring-stone-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 249 / var(--tw-ring-opacity));
}
.tr-ring-stone-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 244 / var(--tw-ring-opacity));
}
.tr-ring-stone-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(231 229 228 / var(--tw-ring-opacity));
}
.tr-ring-stone-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(214 211 209 / var(--tw-ring-opacity));
}
.tr-ring-stone-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 162 158 / var(--tw-ring-opacity));
}
.tr-ring-stone-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 113 108 / var(--tw-ring-opacity));
}
.tr-ring-stone-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity));
}
.tr-ring-stone-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(68 64 60 / var(--tw-ring-opacity));
}
.tr-ring-stone-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(41 37 36 / var(--tw-ring-opacity));
}
.tr-ring-stone-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(28 25 23 / var(--tw-ring-opacity));
}
.tr-ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 242 242 / var(--tw-ring-opacity));
}
.tr-ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity));
}
.tr-ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity));
}
.tr-ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
}
.tr-ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}
.tr-ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}
.tr-ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity));
}
.tr-ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}
.tr-ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity));
}
.tr-ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity));
}
.tr-ring-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 247 237 / var(--tw-ring-opacity));
}
.tr-ring-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 237 213 / var(--tw-ring-opacity));
}
.tr-ring-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity));
}
.tr-ring-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 186 116 / var(--tw-ring-opacity));
}
.tr-ring-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity));
}
.tr-ring-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity));
}
.tr-ring-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity));
}
.tr-ring-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(194 65 12 / var(--tw-ring-opacity));
}
.tr-ring-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(154 52 18 / var(--tw-ring-opacity));
}
.tr-ring-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 45 18 / var(--tw-ring-opacity));
}
.tr-ring-amber-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity));
}
.tr-ring-amber-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity));
}
.tr-ring-amber-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity));
}
.tr-ring-amber-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity));
}
.tr-ring-amber-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity));
}
.tr-ring-amber-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity));
}
.tr-ring-amber-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity));
}
.tr-ring-amber-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity));
}
.tr-ring-amber-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity));
}
.tr-ring-amber-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity));
}
.tr-ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 252 232 / var(--tw-ring-opacity));
}
.tr-ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 249 195 / var(--tw-ring-opacity));
}
.tr-ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 240 138 / var(--tw-ring-opacity));
}
.tr-ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity));
}
.tr-ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 204 21 / var(--tw-ring-opacity));
}
.tr-ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity));
}
.tr-ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(202 138 4 / var(--tw-ring-opacity));
}
.tr-ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 98 7 / var(--tw-ring-opacity));
}
.tr-ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(133 77 14 / var(--tw-ring-opacity));
}
.tr-ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity));
}
.tr-ring-lime-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity));
}
.tr-ring-lime-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity));
}
.tr-ring-lime-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity));
}
.tr-ring-lime-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity));
}
.tr-ring-lime-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity));
}
.tr-ring-lime-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity));
}
.tr-ring-lime-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity));
}
.tr-ring-lime-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity));
}
.tr-ring-lime-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity));
}
.tr-ring-lime-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity));
}
.tr-ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 244 / var(--tw-ring-opacity));
}
.tr-ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity));
}
.tr-ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity));
}
.tr-ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity));
}
.tr-ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity));
}
.tr-ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}
.tr-ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity));
}
.tr-ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity));
}
.tr-ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity));
}
.tr-ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 83 45 / var(--tw-ring-opacity));
}
.tr-ring-emerald-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity));
}
.tr-ring-emerald-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity));
}
.tr-ring-emerald-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity));
}
.tr-ring-emerald-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity));
}
.tr-ring-emerald-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity));
}
.tr-ring-emerald-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity));
}
.tr-ring-emerald-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity));
}
.tr-ring-emerald-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity));
}
.tr-ring-emerald-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity));
}
.tr-ring-emerald-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity));
}
.tr-ring-teal-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 250 / var(--tw-ring-opacity));
}
.tr-ring-teal-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(204 251 241 / var(--tw-ring-opacity));
}
.tr-ring-teal-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity));
}
.tr-ring-teal-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity));
}
.tr-ring-teal-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity));
}
.tr-ring-teal-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity));
}
.tr-ring-teal-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity));
}
.tr-ring-teal-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity));
}
.tr-ring-teal-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity));
}
.tr-ring-teal-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(19 78 74 / var(--tw-ring-opacity));
}
.tr-ring-cyan-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 254 255 / var(--tw-ring-opacity));
}
.tr-ring-cyan-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(207 250 254 / var(--tw-ring-opacity));
}
.tr-ring-cyan-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 243 252 / var(--tw-ring-opacity));
}
.tr-ring-cyan-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity));
}
.tr-ring-cyan-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity));
}
.tr-ring-cyan-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity));
}
.tr-ring-cyan-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity));
}
.tr-ring-cyan-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity));
}
.tr-ring-cyan-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity));
}
.tr-ring-cyan-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 78 99 / var(--tw-ring-opacity));
}
.tr-ring-sky-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity));
}
.tr-ring-sky-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity));
}
.tr-ring-sky-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity));
}
.tr-ring-sky-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity));
}
.tr-ring-sky-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity));
}
.tr-ring-sky-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}
.tr-ring-sky-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity));
}
.tr-ring-sky-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity));
}
.tr-ring-sky-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity));
}
.tr-ring-sky-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity));
}
.tr-ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 246 255 / var(--tw-ring-opacity));
}
.tr-ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 234 254 / var(--tw-ring-opacity));
}
.tr-ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
}
.tr-ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}
.tr-ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity));
}
.tr-ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}
.tr-ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}
.tr-ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity));
}
.tr-ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity));
}
.tr-ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity));
}
.tr-ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity));
}
.tr-ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity));
}
.tr-ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity));
}
.tr-ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity));
}
.tr-ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity));
}
.tr-ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}
.tr-ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}
.tr-ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity));
}
.tr-ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity));
}
.tr-ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(49 46 129 / var(--tw-ring-opacity));
}
.tr-ring-violet-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 243 255 / var(--tw-ring-opacity));
}
.tr-ring-violet-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(237 233 254 / var(--tw-ring-opacity));
}
.tr-ring-violet-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(221 214 254 / var(--tw-ring-opacity));
}
.tr-ring-violet-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(196 181 253 / var(--tw-ring-opacity));
}
.tr-ring-violet-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity));
}
.tr-ring-violet-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity));
}
.tr-ring-violet-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity));
}
.tr-ring-violet-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(109 40 217 / var(--tw-ring-opacity));
}
.tr-ring-violet-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(91 33 182 / var(--tw-ring-opacity));
}
.tr-ring-violet-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(76 29 149 / var(--tw-ring-opacity));
}
.tr-ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity));
}
.tr-ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity));
}
.tr-ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity));
}
.tr-ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity));
}
.tr-ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity));
}
.tr-ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}
.tr-ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity));
}
.tr-ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity));
}
.tr-ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity));
}
.tr-ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 244 255 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 232 255 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 208 254 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 171 252 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(232 121 249 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(162 28 175 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 25 143 / var(--tw-ring-opacity));
}
.tr-ring-fuchsia-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(112 26 117 / var(--tw-ring-opacity));
}
.tr-ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity));
}
.tr-ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity));
}
.tr-ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity));
}
.tr-ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity));
}
.tr-ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity));
}
.tr-ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}
.tr-ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity));
}
.tr-ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity));
}
.tr-ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity));
}
.tr-ring-rose-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 241 242 / var(--tw-ring-opacity));
}
.tr-ring-rose-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 228 230 / var(--tw-ring-opacity));
}
.tr-ring-rose-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 205 211 / var(--tw-ring-opacity));
}
.tr-ring-rose-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 164 175 / var(--tw-ring-opacity));
}
.tr-ring-rose-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 113 133 / var(--tw-ring-opacity));
}
.tr-ring-rose-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity));
}
.tr-ring-rose-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity));
}
.tr-ring-rose-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 18 60 / var(--tw-ring-opacity));
}
.tr-ring-rose-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(159 18 57 / var(--tw-ring-opacity));
}
.tr-ring-rose-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(136 19 55 / var(--tw-ring-opacity));
}
.placeholder\:tr-text-gray-500::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.placeholder\:tr-text-gray-500:-ms-input-placeholder {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.placeholder\:tr-text-gray-500::placeholder {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.visited\:tr-text-blue-500:visited {
    color: rgb(59 130 246 );
}
.hover\:tr-border-b-2:hover {
    border-bottom-width: 2px;
}
.hover\:tr-border-transparent:hover {
    border-color: transparent;
}
.hover\:tr-border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.hover\:tr-border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity));
}
.hover\:tr-border-slate-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity));
}
.hover\:tr-border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity));
}
.hover\:tr-border-zinc-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-border-opacity));
}
.hover\:tr-border-neutral-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-border-opacity));
}
.hover\:tr-border-stone-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-border-opacity));
}
.hover\:tr-border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-border-opacity));
}
.hover\:tr-border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-border-opacity));
}
.hover\:tr-border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity));
}
.hover\:tr-border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity));
}
.hover\:tr-border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity));
}
.hover\:tr-border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.hover\:tr-border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-border-opacity));
}
.hover\:tr-border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-border-opacity));
}
.hover\:tr-border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity));
}
.hover\:tr-border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-border-opacity));
}
.hover\:tr-border-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-border-opacity));
}
.hover\:tr-border-amber-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-border-opacity));
}
.hover\:tr-border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-border-opacity));
}
.hover\:tr-border-lime-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-border-opacity));
}
.hover\:tr-border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-border-opacity));
}
.hover\:tr-border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-border-opacity));
}
.hover\:tr-border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity));
}
.hover\:tr-border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-border-opacity));
}
.hover\:tr-border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity));
}
.hover\:tr-border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity));
}
.hover\:tr-border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity));
}
.hover\:tr-border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-border-opacity));
}
.hover\:tr-border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-border-opacity));
}
.hover\:tr-border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-border-opacity));
}
.hover\:tr-border-emerald-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-border-opacity));
}
.hover\:tr-border-teal-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-border-opacity));
}
.hover\:tr-border-cyan-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-border-opacity));
}
.hover\:tr-border-sky-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-border-opacity));
}
.hover\:tr-border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-border-opacity));
}
.hover\:tr-border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-border-opacity));
}
.hover\:tr-border-violet-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-border-opacity));
}
.hover\:tr-border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-border-opacity));
}
.hover\:tr-border-fuchsia-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-border-opacity));
}
.hover\:tr-border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-border-opacity));
}
.hover\:tr-border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-border-opacity));
}
.hover\:tr-border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-border-opacity));
}
.hover\:tr-border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-border-opacity));
}
.hover\:tr-border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-border-opacity));
}
.hover\:tr-border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-border-opacity));
}
.hover\:tr-border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-border-opacity));
}
.hover\:tr-border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-border-opacity));
}
.hover\:tr-border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-border-opacity));
}
.hover\:tr-border-rose-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-border-opacity));
}
.hover\:tr-bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.hover\:tr-bg-transparent:hover {
    background-color: transparent;
}
.hover\:tr-bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}
.hover\:tr-bg-slate-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
.hover\:tr-bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.hover\:tr-bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.hover\:tr-bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.hover\:tr-bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
.hover\:tr-bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.hover\:tr-bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.hover\:tr-bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.hover\:tr-bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.hover\:tr-bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}
.hover\:tr-bg-zinc-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.hover\:tr-bg-neutral-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}
.hover\:tr-bg-stone-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}
.hover\:tr-bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(154 52 18 / var(--tw-bg-opacity));
}
.hover\:tr-bg-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(124 45 18 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}
.hover\:tr-bg-amber-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(133 77 14 / var(--tw-bg-opacity));
}
.hover\:tr-bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(113 63 18 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}
.hover\:tr-bg-lime-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}
.hover\:tr-bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}
.hover\:tr-bg-emerald-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(17 94 89 / var(--tw-bg-opacity));
}
.hover\:tr-bg-teal-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(19 78 74 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}
.hover\:tr-bg-cyan-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}
.hover\:tr-bg-sky-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}
.hover\:tr-bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}
.hover\:tr-bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}
.hover\:tr-bg-violet-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}
.hover\:tr-bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}
.hover\:tr-bg-fuchsia-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}
.hover\:tr-bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}
.hover\:tr-bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}
.hover\:tr-bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}
.hover\:tr-bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}
.hover\:tr-bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}
.hover\:tr-bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}
.hover\:tr-bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}
.hover\:tr-bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}
.hover\:tr-bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}
.hover\:tr-bg-rose-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}
.hover\:tr-text-transparent:hover {
    color: transparent;
}
.hover\:tr-text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.hover\:tr-text-black:hover {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-50:hover {
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-100:hover {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-200:hover {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-300:hover {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-400:hover {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-500:hover {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-600:hover {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-700:hover {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-800:hover {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity));
}
.hover\:tr-text-slate-900:hover {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
}
.hover\:tr-text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-50:hover {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-100:hover {
    --tw-text-opacity: 1;
    color: rgb(244 244 245 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-200:hover {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-300:hover {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-400:hover {
    --tw-text-opacity: 1;
    color: rgb(161 161 170 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-500:hover {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-600:hover {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-700:hover {
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-800:hover {
    --tw-text-opacity: 1;
    color: rgb(39 39 42 / var(--tw-text-opacity));
}
.hover\:tr-text-zinc-900:hover {
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-50:hover {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-100:hover {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-200:hover {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-300:hover {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-400:hover {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-500:hover {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-600:hover {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-700:hover {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-800:hover {
    --tw-text-opacity: 1;
    color: rgb(38 38 38 / var(--tw-text-opacity));
}
.hover\:tr-text-neutral-900:hover {
    --tw-text-opacity: 1;
    color: rgb(23 23 23 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-50:hover {
    --tw-text-opacity: 1;
    color: rgb(250 250 249 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-100:hover {
    --tw-text-opacity: 1;
    color: rgb(245 245 244 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-200:hover {
    --tw-text-opacity: 1;
    color: rgb(231 229 228 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-300:hover {
    --tw-text-opacity: 1;
    color: rgb(214 211 209 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-400:hover {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-500:hover {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-600:hover {
    --tw-text-opacity: 1;
    color: rgb(87 83 78 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-700:hover {
    --tw-text-opacity: 1;
    color: rgb(68 64 60 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-800:hover {
    --tw-text-opacity: 1;
    color: rgb(41 37 36 / var(--tw-text-opacity));
}
.hover\:tr-text-stone-900:hover {
    --tw-text-opacity: 1;
    color: rgb(28 25 23 / var(--tw-text-opacity));
}
.hover\:tr-text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgb(254 242 242 / var(--tw-text-opacity));
}
.hover\:tr-text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgb(254 226 226 / var(--tw-text-opacity));
}
.hover\:tr-text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgb(254 202 202 / var(--tw-text-opacity));
}
.hover\:tr-text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity));
}
.hover\:tr-text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
}
.hover\:tr-text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}
.hover\:tr-text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity));
}
.hover\:tr-text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity));
}
.hover\:tr-text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity));
}
.hover\:tr-text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgb(255 247 237 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgb(255 237 213 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgb(154 52 18 / var(--tw-text-opacity));
}
.hover\:tr-text-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgb(124 45 18 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-50:hover {
    --tw-text-opacity: 1;
    color: rgb(255 251 235 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-100:hover {
    --tw-text-opacity: 1;
    color: rgb(254 243 199 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-200:hover {
    --tw-text-opacity: 1;
    color: rgb(253 230 138 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-300:hover {
    --tw-text-opacity: 1;
    color: rgb(252 211 77 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-400:hover {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-500:hover {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-600:hover {
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-700:hover {
    --tw-text-opacity: 1;
    color: rgb(180 83 9 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-800:hover {
    --tw-text-opacity: 1;
    color: rgb(146 64 14 / var(--tw-text-opacity));
}
.hover\:tr-text-amber-900:hover {
    --tw-text-opacity: 1;
    color: rgb(120 53 15 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgb(254 252 232 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgb(254 249 195 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgb(254 240 138 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgb(161 98 7 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgb(133 77 14 / var(--tw-text-opacity));
}
.hover\:tr-text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgb(113 63 18 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-50:hover {
    --tw-text-opacity: 1;
    color: rgb(247 254 231 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-100:hover {
    --tw-text-opacity: 1;
    color: rgb(236 252 203 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-200:hover {
    --tw-text-opacity: 1;
    color: rgb(217 249 157 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-300:hover {
    --tw-text-opacity: 1;
    color: rgb(190 242 100 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-400:hover {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-500:hover {
    --tw-text-opacity: 1;
    color: rgb(132 204 22 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-600:hover {
    --tw-text-opacity: 1;
    color: rgb(101 163 13 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-700:hover {
    --tw-text-opacity: 1;
    color: rgb(77 124 15 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-800:hover {
    --tw-text-opacity: 1;
    color: rgb(63 98 18 / var(--tw-text-opacity));
}
.hover\:tr-text-lime-900:hover {
    --tw-text-opacity: 1;
    color: rgb(54 83 20 / var(--tw-text-opacity));
}
.hover\:tr-text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity));
}
.hover\:tr-text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity));
}
.hover\:tr-text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgb(187 247 208 / var(--tw-text-opacity));
}
.hover\:tr-text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity));
}
.hover\:tr-text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
}
.hover\:tr-text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
}
.hover\:tr-text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity));
}
.hover\:tr-text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity));
}
.hover\:tr-text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity));
}
.hover\:tr-text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-50:hover {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-100:hover {
    --tw-text-opacity: 1;
    color: rgb(209 250 229 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-200:hover {
    --tw-text-opacity: 1;
    color: rgb(167 243 208 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-300:hover {
    --tw-text-opacity: 1;
    color: rgb(110 231 183 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-400:hover {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-500:hover {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-600:hover {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-700:hover {
    --tw-text-opacity: 1;
    color: rgb(4 120 87 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-800:hover {
    --tw-text-opacity: 1;
    color: rgb(6 95 70 / var(--tw-text-opacity));
}
.hover\:tr-text-emerald-900:hover {
    --tw-text-opacity: 1;
    color: rgb(6 78 59 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-50:hover {
    --tw-text-opacity: 1;
    color: rgb(240 253 250 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-100:hover {
    --tw-text-opacity: 1;
    color: rgb(204 251 241 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-200:hover {
    --tw-text-opacity: 1;
    color: rgb(153 246 228 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-300:hover {
    --tw-text-opacity: 1;
    color: rgb(94 234 212 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-400:hover {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-500:hover {
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-600:hover {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-700:hover {
    --tw-text-opacity: 1;
    color: rgb(15 118 110 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-800:hover {
    --tw-text-opacity: 1;
    color: rgb(17 94 89 / var(--tw-text-opacity));
}
.hover\:tr-text-teal-900:hover {
    --tw-text-opacity: 1;
    color: rgb(19 78 74 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-50:hover {
    --tw-text-opacity: 1;
    color: rgb(236 254 255 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-100:hover {
    --tw-text-opacity: 1;
    color: rgb(207 250 254 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-200:hover {
    --tw-text-opacity: 1;
    color: rgb(165 243 252 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-300:hover {
    --tw-text-opacity: 1;
    color: rgb(103 232 249 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-400:hover {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-500:hover {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-600:hover {
    --tw-text-opacity: 1;
    color: rgb(8 145 178 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-700:hover {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-800:hover {
    --tw-text-opacity: 1;
    color: rgb(21 94 117 / var(--tw-text-opacity));
}
.hover\:tr-text-cyan-900:hover {
    --tw-text-opacity: 1;
    color: rgb(22 78 99 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-50:hover {
    --tw-text-opacity: 1;
    color: rgb(240 249 255 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-100:hover {
    --tw-text-opacity: 1;
    color: rgb(224 242 254 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-200:hover {
    --tw-text-opacity: 1;
    color: rgb(186 230 253 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-300:hover {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-400:hover {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-500:hover {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-600:hover {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-700:hover {
    --tw-text-opacity: 1;
    color: rgb(3 105 161 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-800:hover {
    --tw-text-opacity: 1;
    color: rgb(7 89 133 / var(--tw-text-opacity));
}
.hover\:tr-text-sky-900:hover {
    --tw-text-opacity: 1;
    color: rgb(12 74 110 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgb(239 246 255 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity));
}
.hover\:tr-text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgb(199 210 254 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgb(55 48 163 / var(--tw-text-opacity));
}
.hover\:tr-text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgb(49 46 129 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-50:hover {
    --tw-text-opacity: 1;
    color: rgb(245 243 255 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-100:hover {
    --tw-text-opacity: 1;
    color: rgb(237 233 254 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-200:hover {
    --tw-text-opacity: 1;
    color: rgb(221 214 254 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-300:hover {
    --tw-text-opacity: 1;
    color: rgb(196 181 253 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-400:hover {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-500:hover {
    --tw-text-opacity: 1;
    color: rgb(139 92 246 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-600:hover {
    --tw-text-opacity: 1;
    color: rgb(124 58 237 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-700:hover {
    --tw-text-opacity: 1;
    color: rgb(109 40 217 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-800:hover {
    --tw-text-opacity: 1;
    color: rgb(91 33 182 / var(--tw-text-opacity));
}
.hover\:tr-text-violet-900:hover {
    --tw-text-opacity: 1;
    color: rgb(76 29 149 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgb(250 245 255 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgb(243 232 255 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgb(233 213 255 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity));
}
.hover\:tr-text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-50:hover {
    --tw-text-opacity: 1;
    color: rgb(253 244 255 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-100:hover {
    --tw-text-opacity: 1;
    color: rgb(250 232 255 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-200:hover {
    --tw-text-opacity: 1;
    color: rgb(245 208 254 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-300:hover {
    --tw-text-opacity: 1;
    color: rgb(240 171 252 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-400:hover {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-500:hover {
    --tw-text-opacity: 1;
    color: rgb(217 70 239 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-600:hover {
    --tw-text-opacity: 1;
    color: rgb(192 38 211 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-700:hover {
    --tw-text-opacity: 1;
    color: rgb(162 28 175 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-800:hover {
    --tw-text-opacity: 1;
    color: rgb(134 25 143 / var(--tw-text-opacity));
}
.hover\:tr-text-fuchsia-900:hover {
    --tw-text-opacity: 1;
    color: rgb(112 26 117 / var(--tw-text-opacity));
}
.hover\:tr-text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgb(253 242 248 / var(--tw-text-opacity));
}
.hover\:tr-text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgb(252 231 243 / var(--tw-text-opacity));
}
.hover\:tr-text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity));
}
.hover\:tr-text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgb(249 168 212 / var(--tw-text-opacity));
}
.hover\:tr-text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity));
}
.hover\:tr-text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity));
}
.hover\:tr-text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity));
}
.hover\:tr-text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgb(190 24 93 / var(--tw-text-opacity));
}
.hover\:tr-text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgb(131 24 67 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-50:hover {
    --tw-text-opacity: 1;
    color: rgb(255 241 242 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-100:hover {
    --tw-text-opacity: 1;
    color: rgb(255 228 230 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-200:hover {
    --tw-text-opacity: 1;
    color: rgb(254 205 211 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-300:hover {
    --tw-text-opacity: 1;
    color: rgb(253 164 175 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-400:hover {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-500:hover {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-600:hover {
    --tw-text-opacity: 1;
    color: rgb(225 29 72 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-700:hover {
    --tw-text-opacity: 1;
    color: rgb(190 18 60 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-800:hover {
    --tw-text-opacity: 1;
    color: rgb(159 18 57 / var(--tw-text-opacity));
}
.hover\:tr-text-rose-900:hover {
    --tw-text-opacity: 1;
    color: rgb(136 19 55 / var(--tw-text-opacity));
}
.hover\:tr-underline:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
.hover\:tr-ring-transparent:hover {
    --tw-ring-color: transparent;
}
.hover\:tr-ring-white:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-black:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity));
}
.hover\:tr-ring-slate-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 23 42 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}
.hover\:tr-ring-gray-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 244 245 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(228 228 231 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 216 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 161 170 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 113 122 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 63 70 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(39 39 42 / var(--tw-ring-opacity));
}
.hover\:tr-ring-zinc-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(24 24 27 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 245 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 163 163 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(115 115 115 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(64 64 64 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity));
}
.hover\:tr-ring-neutral-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(23 23 23 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 249 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 244 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(231 229 228 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(214 211 209 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 162 158 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 113 108 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(68 64 60 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(41 37 36 / var(--tw-ring-opacity));
}
.hover\:tr-ring-stone-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(28 25 23 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 242 242 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity));
}
.hover\:tr-ring-red-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 247 237 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 237 213 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 186 116 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(194 65 12 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(154 52 18 / var(--tw-ring-opacity));
}
.hover\:tr-ring-orange-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 45 18 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity));
}
.hover\:tr-ring-amber-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 252 232 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 249 195 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 240 138 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 204 21 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(202 138 4 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 98 7 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(133 77 14 / var(--tw-ring-opacity));
}
.hover\:tr-ring-yellow-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity));
}
.hover\:tr-ring-lime-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 244 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity));
}
.hover\:tr-ring-green-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 83 45 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity));
}
.hover\:tr-ring-emerald-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 250 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(204 251 241 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity));
}
.hover\:tr-ring-teal-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(19 78 74 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 254 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(207 250 254 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 243 252 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity));
}
.hover\:tr-ring-cyan-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 78 99 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity));
}
.hover\:tr-ring-sky-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 246 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 234 254 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity));
}
.hover\:tr-ring-blue-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity));
}
.hover\:tr-ring-indigo-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(49 46 129 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 243 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(237 233 254 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(221 214 254 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(196 181 253 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(109 40 217 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(91 33 182 / var(--tw-ring-opacity));
}
.hover\:tr-ring-violet-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(76 29 149 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity));
}
.hover\:tr-ring-purple-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 244 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 232 255 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 208 254 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 171 252 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(232 121 249 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(162 28 175 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 25 143 / var(--tw-ring-opacity));
}
.hover\:tr-ring-fuchsia-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(112 26 117 / var(--tw-ring-opacity));
}
.hover\:tr-ring-pink-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity));
}
.hover\:tr-ring-pink-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity));
}
.hover\:tr-ring-pink-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity));
}
.hover\:tr-ring-pink-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity));
}
.hover\:tr-ring-pink-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity));
}
.hover\:tr-ring-pink-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}
.hover\:tr-ring-pink-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity));
}
.hover\:tr-ring-pink-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity));
}
.hover\:tr-ring-pink-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-50:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 241 242 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-100:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 228 230 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-200:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 205 211 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-300:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 164 175 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-400:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 113 133 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-500:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-600:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-700:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 18 60 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-800:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(159 18 57 / var(--tw-ring-opacity));
}
.hover\:tr-ring-rose-900:hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(136 19 55 / var(--tw-ring-opacity));
}
.focus\:tr-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:tr-outline-0:focus {
    outline-width: 0px;
}
.focus\:tr-ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:tr-ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:tr-ring-transparent:focus {
    --tw-ring-color: transparent;
}
.focus\:tr-ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity));
}
.focus\:tr-ring-slate-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 23 42 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}
.focus\:tr-ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 244 245 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(228 228 231 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 216 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 161 170 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 113 122 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 63 70 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(39 39 42 / var(--tw-ring-opacity));
}
.focus\:tr-ring-zinc-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(24 24 27 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 245 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 163 163 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(115 115 115 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(64 64 64 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity));
}
.focus\:tr-ring-neutral-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(23 23 23 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 249 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 244 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(231 229 228 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(214 211 209 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 162 158 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 113 108 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(68 64 60 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(41 37 36 / var(--tw-ring-opacity));
}
.focus\:tr-ring-stone-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(28 25 23 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 242 242 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity));
}
.focus\:tr-ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 247 237 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 237 213 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 186 116 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(194 65 12 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(154 52 18 / var(--tw-ring-opacity));
}
.focus\:tr-ring-orange-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 45 18 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity));
}
.focus\:tr-ring-amber-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 252 232 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 249 195 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 240 138 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 204 21 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(202 138 4 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 98 7 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(133 77 14 / var(--tw-ring-opacity));
}
.focus\:tr-ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity));
}
.focus\:tr-ring-lime-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 244 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity));
}
.focus\:tr-ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 83 45 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity));
}
.focus\:tr-ring-emerald-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 250 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(204 251 241 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity));
}
.focus\:tr-ring-teal-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(19 78 74 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 254 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(207 250 254 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 243 252 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity));
}
.focus\:tr-ring-cyan-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 78 99 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity));
}
.focus\:tr-ring-sky-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 246 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 234 254 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity));
}
.focus\:tr-ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity));
}
.focus\:tr-ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(49 46 129 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 243 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(237 233 254 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(221 214 254 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(196 181 253 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(109 40 217 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(91 33 182 / var(--tw-ring-opacity));
}
.focus\:tr-ring-violet-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(76 29 149 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity));
}
.focus\:tr-ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 244 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 232 255 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 208 254 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 171 252 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(232 121 249 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(162 28 175 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 25 143 / var(--tw-ring-opacity));
}
.focus\:tr-ring-fuchsia-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(112 26 117 / var(--tw-ring-opacity));
}
.focus\:tr-ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity));
}
.focus\:tr-ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity));
}
.focus\:tr-ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity));
}
.focus\:tr-ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity));
}
.focus\:tr-ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity));
}
.focus\:tr-ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}
.focus\:tr-ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity));
}
.focus\:tr-ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity));
}
.focus\:tr-ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 241 242 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 228 230 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 205 211 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 164 175 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 113 133 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 18 60 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(159 18 57 / var(--tw-ring-opacity));
}
.focus\:tr-ring-rose-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(136 19 55 / var(--tw-ring-opacity));
}
.focus\:tr-ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
}
@media (min-width: 640px) {

    .sm\:tr-col-span-1 {
        grid-column: span 1 / span 1;
    }

    .sm\:tr-col-span-2 {
        grid-column: span 2 / span 2;
    }

    .sm\:tr-col-span-3 {
        grid-column: span 3 / span 3;
    }

    .sm\:tr-col-span-4 {
        grid-column: span 4 / span 4;
    }

    .sm\:tr-col-span-5 {
        grid-column: span 5 / span 5;
    }

    .sm\:tr-col-span-6 {
        grid-column: span 6 / span 6;
    }

    .sm\:tr-col-span-7 {
        grid-column: span 7 / span 7;
    }

    .sm\:tr-col-span-8 {
        grid-column: span 8 / span 8;
    }

    .sm\:tr-col-span-9 {
        grid-column: span 9 / span 9;
    }

    .sm\:tr-col-span-10 {
        grid-column: span 10 / span 10;
    }

    .sm\:tr-col-span-11 {
        grid-column: span 11 / span 11;
    }

    .sm\:tr-col-span-12 {
        grid-column: span 12 / span 12;
    }

    .sm\:tr-grid-cols-none {
        grid-template-columns: none;
    }

    .sm\:tr-grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr));
    }

    .sm\:tr-grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
}
@media (min-width: 768px) {

    .md\:tr-col-span-1 {
        grid-column: span 1 / span 1;
    }

    .md\:tr-col-span-2 {
        grid-column: span 2 / span 2;
    }

    .md\:tr-col-span-3 {
        grid-column: span 3 / span 3;
    }

    .md\:tr-col-span-4 {
        grid-column: span 4 / span 4;
    }

    .md\:tr-col-span-5 {
        grid-column: span 5 / span 5;
    }

    .md\:tr-col-span-6 {
        grid-column: span 6 / span 6;
    }

    .md\:tr-col-span-7 {
        grid-column: span 7 / span 7;
    }

    .md\:tr-col-span-8 {
        grid-column: span 8 / span 8;
    }

    .md\:tr-col-span-9 {
        grid-column: span 9 / span 9;
    }

    .md\:tr-col-span-10 {
        grid-column: span 10 / span 10;
    }

    .md\:tr-col-span-11 {
        grid-column: span 11 / span 11;
    }

    .md\:tr-col-span-12 {
        grid-column: span 12 / span 12;
    }

    .md\:tr-grid-cols-none {
        grid-template-columns: none;
    }

    .md\:tr-grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr));
    }

    .md\:tr-grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
}
@media (min-width: 1024px) {

    .lg\:tr-col-span-1 {
        grid-column: span 1 / span 1;
    }

    .lg\:tr-col-span-2 {
        grid-column: span 2 / span 2;
    }

    .lg\:tr-col-span-3 {
        grid-column: span 3 / span 3;
    }

    .lg\:tr-col-span-4 {
        grid-column: span 4 / span 4;
    }

    .lg\:tr-col-span-5 {
        grid-column: span 5 / span 5;
    }

    .lg\:tr-col-span-6 {
        grid-column: span 6 / span 6;
    }

    .lg\:tr-col-span-7 {
        grid-column: span 7 / span 7;
    }

    .lg\:tr-col-span-8 {
        grid-column: span 8 / span 8;
    }

    .lg\:tr-col-span-9 {
        grid-column: span 9 / span 9;
    }

    .lg\:tr-col-span-10 {
        grid-column: span 10 / span 10;
    }

    .lg\:tr-col-span-11 {
        grid-column: span 11 / span 11;
    }

    .lg\:tr-col-span-12 {
        grid-column: span 12 / span 12;
    }

    .lg\:tr-grid-cols-none {
        grid-template-columns: none;
    }

    .lg\:tr-grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr));
    }

    .lg\:tr-grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
}
.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}